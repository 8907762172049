import triplaLocalStorage from 'utilities/triplaLocalStorage'

const TEM_MINUTES = 10 * 60 * 1000
const PREFIX = 'TriplaBrowingState_'

export function remove(key) {
  return triplaLocalStorage.removeItem(PREFIX + key)
}

export function save(key, payload, expires = TEM_MINUTES) {
  return triplaLocalStorage.setItem(
    PREFIX + key,
    JSON.stringify({
      payload: payload,
      expires: new Date().getTime() + expires
    })
  )
}

export function load(key) {
  try {
    const data = JSON.parse(triplaLocalStorage.getItem(PREFIX + key))
    remove(key)
    if (data?.expires >= new Date().getTime()) {
      return data.payload
    }
  } catch (e) {
    console.error(e)
  }
}

export default { save, load, remove }
