import API from './fetch'
import { getMyVouchersURL, consumeVoucherURL, getAppliedCouponCodeURL } from 'api/url'

const Voucher = {
  getMyVouchers(payload) {
    return API.getData(getMyVouchersURL(payload))
  },
  getAppliedCouponCodeURL(couponCode) {
    return API.getData(getAppliedCouponCodeURL(couponCode))
  },
  consumeVoucher(voucherId) {
    return API.putData(consumeVoucherURL(voucherId))
  }
}

export default Voucher
