import { findDayAfterToday, parseDate, formatDate02, addDay } from 'utilities/date'
import { mapGetters } from 'vuex'
import {
  buildKidsAges,
  buildKidsTiers,
  buildRooms,
  genKidsAgesTransferPayload
} from 'utilities/generateSearchForm'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'
import Hotels from 'api/Hotels'
import { CROSS_DOMAIN_TRACKING_KEY } from 'src/constants/tripla'

const SEARCH_QUERIES = [
  'checkin',
  'checkout',
  'is_including_occupied',
  'rooms',
  'is_day_use',
  'filter',
  'nights',
  'search_type',
  'coupon_code',
  'order',
  'type'
]

export default {
  data() {
    return {
      warning: this.$route.query.warning || null
    }
  },

  computed: {
    ...mapGetters({
      isVoucherMode: 'search/isVoucherMode',
      initialSetting: 'setting/getInit',
      defaultSearchType: 'setting/getSearchType',
      isTiersMode: 'setting/isTiersMode',
      defaultSearch: 'setting/getDefautSearch',
      bookAvailableRoomsOnlyDefaultSetting: 'setting/bookAvailableRoomsOnlyDefaultSetting',
      hotelCode: 'setting/getHotelCode',
      isMultiRoomSearchEnabled: 'setting/isMultiRoomSearchEnabled',
      brandID: 'setting/getBrandID',
      wbfSetting: 'setting/getWbfSetting'
    })
  },

  methods: {
    assignQueryToSearchForm(previousQuery = {}) {
      const query = { ..._pick(previousQuery, SEARCH_QUERIES), ...this.$route.query }
      return new Promise((resolve) => {
        const {
          adults,
          checkin,
          checkout,
          children,
          type = this.defaultSearchType,
          room_type_code: roomTypeCode,
          hotel_plan_code: hotelPlanCode,
          room_plan_code: roomPlanCode,
          mode,
          promo_code,
          order = this.defaultSearch?.sort_order,
          is_including_occupied,
          kids_ages: kidsAges,
          coupon_code,
          voucher_coupon_code,
          room_count,
          utm_source,
          utm_medium,
          utm_campaign,
          campaignid,
          adgroupid,
          keyword,
          gclid,
          yclid,
          medium,
          source,
          email_id,
          search_type,
          nights,
          price_displayed_total,
          google_site,
          google_promo_code,
          currency,
          metasearch_source,
          dynamic_package,
          lottery_event: lottery_event_uuid,
          current_lottery_entry,
          lottery_access_token
        } = query
        let {
          room_type_codes: roomTypeCodes,
          hotel_plan_codes: hotelPlanCodes,
          room_plan_codes: roomPlanCodes,
          is_day_use: isDayUse,
          filter
        } = query
        const kidsAgesList = buildKidsAges(kidsAges)
        roomTypeCodes = _isEmpty(roomTypeCodes) ? [] : roomTypeCodes?.split(',')
        hotelPlanCodes = _isEmpty(hotelPlanCodes) ? [] : hotelPlanCodes?.split(',')
        roomPlanCodes = _isEmpty(roomPlanCodes) ? [] : roomPlanCodes?.split(',')
        isDayUse = isDayUse === 'true'

        // We support 2 syntaxes for filters
        // ?filter=1&filter=2&filter=3
        // or ?filter=1,2,3
        if (!_isEmpty(filter) && !Array.isArray(filter) && filter.includes(',')) {
          filter = filter.split(',')
        }

        // Force filter to be an array since there maybe only 1 filter in the URL query
        if (!Array.isArray(filter) && !!filter) {
          filter = [filter]
        }

        // If both room_type_codes and room_type_code are present in the URL, take room_type_codes
        if (_isEmpty(roomTypeCodes) && roomTypeCode) {
          roomTypeCodes = [roomTypeCode]
        }

        // Same as above
        if (_isEmpty(hotelPlanCodes) && hotelPlanCode) {
          hotelPlanCodes = [hotelPlanCode]
        }

        if (_isEmpty(roomPlanCodes) && roomPlanCode) {
          roomPlanCodes = [roomPlanCode]
        }

        if (
          /\b(oogle_hotel_ads|google_hotel_ads)\b/.test(metasearch_source) && // oogle is for cypress
          (process.env.IGNORE_GHA_PLAN_ROOM_FILTER || '').split(',').includes(String(this.brandID))
        ) {
          hotelPlanCodes = []
          roomTypeCodes = []
        }
        const DynamicPackageCheckin =
          dynamic_package === 'wbf'
            ? findDayAfterToday(this.wbfSetting?.settings?.check_in_start_day)
            : null
        const checkinDate =
          checkin ||
          DynamicPackageCheckin ||
          findDayAfterToday(this.defaultSearch.checkin_date_offset)
        const checkInDateParsed = parseDate(checkinDate, 'YYYY/MM/DD')
        const checkOutDateParsed = checkInDateParsed.add(0, 'day')
        const checkOutDateDayUse = formatDate02(checkOutDateParsed, 'YYYY/MM/DD', 'en')

        const checkoutDate = isDayUse ? checkOutDateDayUse : checkout || addDay(checkinDate, 1)
        let searchFormData = {
          checkin: checkinDate,
          checkout: checkoutDate,
          type,
          order,
          filter,
          is_day_use: isDayUse,
          mode,
          promo_code,
          roomTypeCodes,
          hotelPlanCodes,
          roomPlanCodes,
          is_including_occupied: [true, 'true'].includes(is_including_occupied),
          utm_source,
          utm_medium,
          utm_campaign,
          campaignid,
          adgroupid,
          [CROSS_DOMAIN_TRACKING_KEY]: query[CROSS_DOMAIN_TRACKING_KEY], // Persist _gl for GA cross domain tracking
          keyword,
          gclid,
          yclid,
          medium,
          source,
          email_id,
          defineCheckInOnUrl: !!checkin,
          price_displayed_total,
          google_site,
          google_promo_code,
          currency,
          current_lottery_entry,
          lottery_event_uuid,
          lottery_access_token
        }

        if (mode === 'secret' && hotelPlanCodes.length === 0) {
          searchFormData.mode = null
        }

        searchFormData.dynamic_package = dynamic_package === 'wbf' ? dynamic_package : null

        if (!this.isVoucherMode) {
          if (_isNil(is_including_occupied)) {
            searchFormData.is_including_occupied = !this.bookAvailableRoomsOnlyDefaultSetting
          }
        }

        if (coupon_code) {
          searchFormData.coupon_code = coupon_code
        }
        if (voucher_coupon_code) {
          searchFormData.voucher_coupon_code = voucher_coupon_code
        }

        if (search_type === 'undated') {
          searchFormData.search_type = search_type
          searchFormData.nights = nights ? Number(nights) : 1
        }

        const rooms = buildRooms(query.rooms, Number(adults) || undefined)

        if (this.isTiersMode && !this.$preview) {
          searchFormData = {
            ...searchFormData,
            adults: Number(adults) || rooms[0].adults,
            rooms: []
          }

          if (this.isMultiRoomSearchEnabled) {
            searchFormData.room_count =
              Math.min(room_count, this.initialSetting.multi_rooms_search_limit) || 1
          }

          if (kidsAgesList.length > 0) {
            const apiQuery = genKidsAgesTransferPayload({
              ...searchFormData,
              kidsAges: kidsAgesList
            })
            Hotels.getFacilities({
              hotel_codes: [this.hotelCode],
              is_including_lowest_price: false,
              rooms: apiQuery.rooms
            })
              .then((res) => {
                const kidsTiers = buildKidsTiers(
                  JSON.stringify(res.data[0].search_params.rooms[0].kids_age_range),
                  this.initialSetting
                )
                searchFormData.kidsTiers = kidsTiers
                if (kidsAgesList.length !== kidsTiers.reduce((acc, cur) => acc + cur.children, 0)) {
                  this.warning = 'kids'
                }
                resolve(searchFormData)
              })
              .catch(() => {
                searchFormData.kidsTiers = []
                resolve(searchFormData)
              })
          } else {
            const kidsTiers = buildKidsTiers(query.kids_tiers, this.initialSetting)
            searchFormData.kidsTiers = kidsTiers
            resolve(searchFormData)
          }
        } else {
          searchFormData = {
            ...searchFormData,
            rooms: buildRooms(query.rooms, Number(adults) || undefined, Number(children) || 0),
            kidsTiers: null
          }
          resolve(searchFormData)
        }
      })
    }
  }
}
