export function appendScript(documentElement = 'body', scriptString) {
  const divEl = document.createElement('div')
  divEl.innerHTML = scriptString
  for (let i = 0; i < divEl.childNodes.length; i++) {
    const node = divEl.childNodes[i]
    if (node.tagName === 'SCRIPT') {
      const el = document.createElement('script')
      el.innerHTML = node.innerHTML
      document[documentElement].appendChild(el)
    }
  }
}

export function dynamicVariablesReplace(scriptText, dynamicValueMap) {
  let formattedText = scriptText
  const variables = [
    'transaction_id',
    'transaction_total_price',
    'hotel_id',
    'hotel_name',
    'plan_id',
    'plan_name',
    'room_id',
    'room_name',
    'checkin_date',
    'checkout_date'
  ]
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i]
    if (dynamicValueMap[variable]) {
      const stringRegex = new RegExp(`'{{${variable}}}'`, 'g')
      formattedText = formattedText.replace(stringRegex, `'${dynamicValueMap[variable]}'`)
      const regex = new RegExp(`{{${variable}}}`, 'g')
      formattedText = formattedText.replace(regex, `'${dynamicValueMap[variable]}'`)
    }
  }
  return formattedText
}
