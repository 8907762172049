const state = {
  initError: null,
  searchResultError: null,
  roomLotteryError: null,
  reservationError: null,
  reservationWarning: {
    type: null,
    messages: null
  },
  globalError: null,
  customFetchError: null
}

const getters = {
  getInitError: (state) => state.initError,
  getSearchResultError: (state) => state.searchResultError,
  getRoomLotteryError: (state) => state.roomLotteryError,
  getReservationError: (state) => state.reservationError,
  getReservationWarning: (state) => state.reservationWarning,
  getGlobalError: (state) => state.globalError,
  getCustomFetchError: (state) => state.customFetchError
}

const mutations = {
  setInitError: (state, payload) => {
    state.initError = payload
  },
  setSearchResultError: (state, payload) => {
    state.searchResultError = payload
  },
  setRoomLotteryError: (state, payload) => {
    state.roomLotteryError = payload
  },
  setReservationError: (state, payload) => {
    state.reservationError = payload
  },
  setReservationWarning: (state, payload) => {
    state.reservationWarning = payload
  },
  clearReservationError: (state) => {
    state.reservationError = null
  },
  setGlobalError: (state, payload) => {
    state.globalError = payload
  },
  setCustomFetchError: (state, payload) => {
    state.customFetchError = payload
  }
}

const actions = {
  setInitError: ({ commit }, payload) => {
    commit('setInitError', payload)
  },
  setSearchResultError: ({ commit }, payload) => {
    commit('setSearchResultError', payload)
  },
  setRoomLotteryError: ({ commit }, payload) => {
    commit('setRoomLotteryError', payload)
  },
  setReservationError: ({ commit }, payload) => {
    commit('setReservationError', payload)
  },
  setReservationWarning: ({ commit }, payload) => {
    commit('setReservationWarning', payload)
  },
  clearReservationError: ({ commit }) => {
    commit('clearReservationError')
  },
  setGlobalError: ({ commit }, payload) => {
    commit('setGlobalError', payload)
  },
  clearGlobalError: ({ commit }) => {
    commit('setGlobalError', null)
  },
  setCustomFetchError: ({ commit }, payload) => {
    commit('setCustomFetchError', payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
