import Hotels from 'api/Hotels'
import i18n from 'src/i18n'

const state = {
  hotelInfo: null
}

const getters = {
  getHotelInfo: (state) => state.hotelInfo,
  getFullAddress: (state) =>
    state.hotelInfo
      ? [
          // https://github.com/umami-dev/tripla_booking_widget/pull/2840#pullrequestreview-1831004619
          i18n.locale === 'ja' && state.hotelInfo.address?.post_code,
          state.hotelInfo.address?.full_address,
          i18n.locale !== 'ja' && state.hotelInfo.address?.post_code,
          state.hotelInfo.address?.country
        ]
          .map((s) => s?.trim?.())
          .filter(Boolean)
          .join(', ')
      : ''
}

const mutations = {
  setHotelInfo: (state, payload) => {
    state.hotelInfo = payload
  }
}

const actions = {
  fetchHotelInfo({ commit }, id) {
    return Hotels.getInfo(id).then((res) => {
      commit('setHotelInfo', res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
