import Vue from 'vue'

Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

Vue.directive('guard', function (el, binding) {
  el.style.opacity = binding.value ? '0.4' : '1'
  el.style.pointerEvents = binding.value ? 'none' : ''
})
