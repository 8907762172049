const localeMapping = {
  en: 'en_US',
  ja: 'ja_JP',
  ko: 'ko_KR',
  zh_Hans: 'zh_CN',
  zh_Hant: 'zh_TW'
}

const state = {
  FB: null
}

const getters = {
  getFbObject: (state) => state.FB
}

const mutations = {
  setState: (state, { attr, value }) => {
    state[attr] = value
  }
}

const actions = {
  initFB: ({ rootState }) => {
    const customFacebookApp = rootState.setting.loginWidget.sns_auth_settings_attributes?.find(
      (item) => item.provider === 'facebook'
    )

    state.FB.init({
      appId: customFacebookApp?.app_id || process.env.FACEBOOK_APP_ID,
      xfbml: true,
      version: 'v14.0',
      cookie: false
    })
  },
  initialize: ({ commit, dispatch }, locale) => {
    window.fbAsyncInit = function () {
      commit('setState', { attr: 'FB', value: FB }) // eslint-disable-line no-undef
      dispatch('initFB')
      state.FB.AppEvents.logPageView() // eslint-disable-line no-undef
    }
    ;((d, s, id) => {
      let js = d.getElementsByTagName(s)[0]
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = `https://connect.facebook.net/${localeMapping[locale]}/sdk.js`
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
