import Vuex from 'vuex'
import Vue from 'vue'
import search from './modules/search'
import setting from './modules/setting'
import error from './modules/error'
import booking from './modules/booking'
import membership from './modules/membership'
import facebook from './modules/facebook'
import extras from './modules/extras'
import customerSegment from './modules/customerSegment'
import hotel from './modules/hotel'
import lottery from './modules/lottery'
import mcp from './modules/mcp'
import receipt from './modules/receipt'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  modules: {
    search,
    setting,
    error,
    booking,
    membership,
    facebook,
    customerSegment,
    hotel,
    extras,
    lottery,
    mcp,
    receipt
  }
})
