import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Vue from 'vue'
import i18n from '../i18n'
import { dateFormatFromServer } from 'constants/tripla'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
dayjs.extend(customParseFormat)

let browserLanguage = i18n.locale

if (browserLanguage === 'zh_Hans') {
  browserLanguage = 'zh-cn'
} else if (browserLanguage === 'zh_Hant') {
  browserLanguage = 'zh-tw'
}

Vue.filter('date', (value) => {
  if (!value) return ''
  const dateValue = dayjs(value, dateFormatFromServer).locale(browserLanguage)
  return dateValue.format('D')
})

Vue.filter('monthYear', (value) => {
  if (!value) return ''
  const dateValue = dayjs(value, dateFormatFromServer).locale(browserLanguage)
  return dateValue.format('YYYY MMM')
})

Vue.filter('dayOfWeek', (value) => {
  if (!value) return ''
  const dateValue = dayjs(value, dateFormatFromServer).locale(browserLanguage)
  return dateValue.format('dddd')
})
