<template>
  <div v-show="visible || localVisible" class="loading" :class="{ fixed: modifiers?.fixed }">
    <b-spinner label="Loading" class="spinner"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'GlobalLoading',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modifiers: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;

  .spinner {
    width: 128px;
    height: 128px;
    margin-top: 100px;
  }

  &.fixed {
    position: fixed;
  }
}
</style>
