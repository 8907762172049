import fetch from 'isomorphic-fetch'
import store from 'store'
import { getUserToken } from 'utilities/membership'
import _get from 'lodash/get'
import _flatten from 'lodash/flatten'
import _flatMap from 'lodash/flatMap'
import _compact from 'lodash/compact'
import { getSessionsTokenURL } from 'api/url'
import { genQueryParamsResultPage, generateSearchForm } from 'utilities/generateSearchForm'
import ERROR_CODES from 'constants/errorCode'
import paths from 'routes/paths'
import router from 'routes'
import i18n from '../i18n'
import { setTriplaUID } from 'utilities/triplaUID'
import { setClientSession, deleteClientSession } from 'utilities/clientSessionCookie'
import { v4 as uuid } from 'uuid'
import { isOta } from 'constants/tripla'

const refreshToken = async () => {
  const res = await fetchWithToken({
    url: getSessionsTokenURL(),
    method: 'POST',
    data: { key: process.env.IDENTITY_KEY, secret: process.env.IDENTITY_SECRET },
    type: 'auth'
  })
  if (res.status && res.status >= 200 && res.status < 400) {
    const json = await res.json()
    const sessionToken = json?.data?.client_session
    if (sessionToken) {
      setClientSession(sessionToken)
      store.commit('setting/setSessionToken', sessionToken)
      return
    }
  }
  throw res
}

// TODO: fetchData unit tests
const fetchWithToken = ({
  url = '',
  method = 'GET',
  data = null,
  type = 'json',
  headers: extraHeaders
}) => {
  try {
    const options = getFetchOptions(method, data, type, extraHeaders, url)
    const fetchJob = () =>
      fetch(url, options).catch((e) => {
        console.info(`fetchJob catch: ${JSON.stringify(e)} / url: ${url}`)
        console.info(`fetchJob options: ${JSON.stringify(options)}`)
        console.info(e)
        throw e
      })
    return fetchJob().then(retryWhen429(fetchJob, -1))
  } catch (e) {
    throw e
  }
}

const retryWhen429 = (retryJob, maxRetries = 0, delay = 1000) => (response) => {
  if (response?.status !== 429 || maxRetries === 0) return response

  return new Promise((resolve) => setTimeout(resolve, delay || 0))
    .then(retryJob)
    .then(retryWhen429(retryJob, Math.max(-1, maxRetries - 1), delay))
}

// refresh IDP token when status === 403 && error code === 4000, then retry fetch
const fetchWithTokenRetry = async (args) => {
  try {
    const res = await fetchWithToken(args)
    if (res?.status !== 403) {
      return res
    }
    console.info(`fetchWithTokenRetry status: ${res?.status}`)
    const errObj = await errorFactory(res)
    console.info(`fetchWithTokenRetry errObj: ${JSON.stringify(errObj)}`)

    if (errObj?.code === ERROR_CODES.IDP_EXPIRED) {
      await refreshToken()
      return fetchWithToken(args)
    }
    return errObj
  } catch (e) {
    console.info(`fetchWithTokenRetry catch: ${JSON.stringify(e)}`)
    throw e
  }
}

const checkHttpStatus = async (response, url) => {
  const isFromFirstPartyDomain =
    store.getters['setting/isFirstPartyDomainEnable'] &&
    url.includes(store.getters['setting/getFirstPartyDomainSetting']?.domain_name)
  if (response.status === 204) {
    if (isFromFirstPartyDomain) {
      setTriplaUID(response.headers.get('Tripla-Uid'))
    }
    return response.text()
  } else if (response.status >= 200 && response.status < 303) {
    if (isFromFirstPartyDomain) {
      setTriplaUID(response.headers.get('Tripla-Uid'))
    }
    if (
      response.headers.get('Content-Type') &&
      (response.headers.get('Content-Type').includes('application/pdf') ||
        response.headers.get('Content-Type').includes('application/zip'))
    ) {
      return response.blob()
    }
    return response.json()
  } else if (response.status === 401) {
    // Logout current user if receive 401 error from the API
    // Note that this is potentially a conflict with shouldRefreshToken
    store.dispatch('membership/logoutLocally')
    store.dispatch('error/setGlobalError', i18n.t('base.logoutMessage'))
    if (isFromFirstPartyDomain && url.includes('user/whoami')) {
      setTriplaUID(response.headers.get('Tripla-Uid'))
    }

    if (store.getters['setting/isInChatbotIframe']) {
      router.push({ name: paths.membership.signIn })
    } else if (!isOta) {
      goToSearchPage()
    }

    if (isOta) {
      const error = await errorFactory(response)
      if (error.title === 'Invalid Client-Session') {
        // If client session is not valid
        deleteClientSession()
        location.reload()
      }
      throw await error
    }
    throw await errorFactory(response)
  } else if (response.status === 503 && !location.href.includes('/503')) {
    window.location = `/503?code=${store.getters['setting/getHotelCode']}`
  } else {
    const error = await errorFactory(response)
    console.log('errorerrorerror', { error })
    throw error
  }
}

const errorFactory = async (response) => {
  console.info(`api status: ${response.status}`)
  console.info(`api response: ${JSON.stringify(response)}`)

  if (!response?.json || response?.isTriplaErrObject) {
    return response
  }

  const jsonParseResult = response.json()
  const json = await jsonParseResult.catch(() => {})

  const errors = _compact(_flatten([json?.errors, json?.error]))
  const error = errors[0]

  const errObj = new Error(error?.title || response.message || response.statusText)

  errObj.title = error?.title
  errObj.status = response.status
  errObj.response = response
  errObj.isTriplaErrObject = true
  errObj.json = json
  // for legacy code
  errObj.response.json = () => jsonParseResult
  // logic from $getErrorMessages and $getFirstErrorInfo and some more.
  errObj.error = error
  errObj.errors = errors
  errObj.codes = _flatMap(errors, (err) => _flatMap(_flatMap(err?.details), (base) => base?.code))
  errObj.code = errObj.codes[0]
  errObj.payloads = _flatMap(errors, (err) => _flatMap(_flatMap(err?.details), (base) => base))
  errObj.payload = errObj.payloads[0]
  errObj.message = error?.details?.base?.[0]?.error || errObj.message
  errObj.titleList = errors.map((e) => e.title || e)

  return errObj
}

const getFetchOptions = (method, data, type, extraHeaders, url) => {
  const headers = getHeaders(type, extraHeaders)
  const body = getBody(data, type)
  const credentials =
    store.getters['setting/isFirstPartyDomainEnable'] &&
    url.includes(store.getters['setting/getFirstPartyDomainSetting']?.domain_name)
      ? 'include'
      : 'omit'

  return { ...{ headers, method }, ...body, credentials }
}

const getHeaders = (type, extraHeaders = {}) => {
  let header = {
    Accept: '*/*',
    'X-Tripla-Tracing-Id': `Root=BW${PACKAGE_VERSION}-${uuid()}-${COMMIT_HASH}`,
    'App-Version': isOta ? 'tripla-ota/1.0' : 'tripla-booking-widget/1.0',
    ...extraHeaders
  }

  const siteController = store.getters['setting/getSiteController']
  if (siteController) {
    header['X-Site-Controller'] = siteController
  }

  if (type === 'auth') {
    return {
      ...header,
      'Content-Type': 'application/json'
    }
  }

  if (type === 'json') {
    const pubnubAuthKey = _get(store.getters['setting/getInit'], 'pubnub.auth_key', null)

    header = {
      ...header,
      'Content-Type': 'application/json',
      'Tripla-Locale': i18n.locale,
      'Tripla-Pubnub': pubnubAuthKey || '' // prevent sending 'null'
    }

    const emailConversionTrackingId = getEmailConversionTrackingId()
    if (emailConversionTrackingId) {
      header['Email-Conversion-Tracking-Id'] = emailConversionTrackingId
    }
  }

  const sessionToken = store.getters['setting/getSessionToken']
  if (sessionToken) {
    header = { ...header, 'Client-Session': sessionToken }
  }

  const token = getUserToken()
  if (token) {
    header = { ...header, Authorization: token }
  }

  return header
}

const getBody = (body, type) => {
  if (type === 'json' || type === 'auth') {
    return body ? { body: JSON.stringify(body) } : {}
  }

  return { body }
}

function ignoreNavigationDuplicated(err) {
  if (err.name !== 'NavigationDuplicated') {
    throw err
  }
}

// TODO: duplicated code with mixins/goToSearchPageMixin
const goToSearchPage = () => {
  let currentSearchForm = store.getters['search/getSearchForm']
  const isTiersMode = store.getters['setting/isTiersMode']

  if (!currentSearchForm || !currentSearchForm.checkin) {
    // Generate default search form
    const defaultSearchFormData = generateSearchForm(currentSearchForm)
    store.dispatch('search/setSearchForm', defaultSearchFormData)
    currentSearchForm = defaultSearchFormData
  }
  const query = genQueryParamsResultPage(currentSearchForm, isTiersMode)
  if (store.getters['setting/isBrandFacility']) {
    router.push({ name: paths.facilities, query }).catch(ignoreNavigationDuplicated)
  } else {
    router.push({ name: paths.booking.result, query }).catch(ignoreNavigationDuplicated)
  }
}

const isIgnoredErrors = (url) => {
  const isOrgMembershipFetchFailed =
    store.getters['membership/isCorporateSignedIn'] &&
    /hotel_brands\/\d+\/membership_programs\/\d+/.test(url)

  return isOrgMembershipFetchFailed
}

export const fetchData = ({ url = '', ...rest }) => {
  if (store.getters['membership/isSignedIn'] && !getUserToken()) {
    store.dispatch('membership/logoutLocally')
  }
  return fetchWithTokenRetry({ url, ...rest })
    .then((response) => checkHttpStatus(response, url))
    .catch((err) => {
      const errMSG = JSON.stringify(err)
      console.info(`in fetch error: ${errMSG}`)

      if (!isIgnoredErrors(url)) {
        store.dispatch('error/setCustomFetchError', errMSG)
      }

      if (!err.json) {
        const basicMessage = i18n.t('base.error02')
        err.error = basicMessage
        err.errors = [{ title: basicMessage }]
        err.titleList = [basicMessage]
        err.isLocalException = true

        if (!isIgnoredErrors(url)) {
          store.dispatch('error/setCustomFetchError', JSON.stringify({ title: basicMessage }))
        }
        throw err
      }
      throw err
    })
}

function getEmailConversionTrackingId() {
  const searchQuery = new URL(window.location).searchParams
  const trackingId = searchQuery.get('email_conversion_tracking_id')

  return trackingId || null
}
