function assignMembershipLabelFilter(
  rootGetters,
  pageLabel,
  options = { rankAll: false, ignoreRank: false }
) {
  const isUserSignedIn = rootGetters['membership/isUserSignedIn']
  const currentUser = rootGetters['membership/getCurrentUser']
  const isCorporateSignedIn = rootGetters['membership/isCorporateSignedIn']
  const organization = rootGetters['membership/getOrganization']
  const labelFilter = {
    labels: [pageLabel]
  }
  if (isUserSignedIn) {
    if (currentUser.program_id) {
      labelFilter.labels.push(`membership_program_${currentUser.program_id}`)
    }

    if (currentUser.rank_id && !options.ignoreRank) {
      if (options.rankAll) {
        labelFilter.labels.push('membership_rank_all')
      } else {
        labelFilter.labels.push(`membership_rank_${currentUser.rank_id}`)
      }
    }
  }
  if (isCorporateSignedIn) {
    if (organization.id) {
      labelFilter.labels.push(`membership_organization_${organization.id}`)
    }
  }
  return labelFilter
}

export function generateBrandLabelFilters(pageLabel, rootGetters) {
  const labelFilters = [{ labels: [pageLabel, 'all_users'] }]
  const isUserSignedIn = rootGetters['membership/isUserSignedIn']
  const isCorporateSignedIn = rootGetters['membership/isCorporateSignedIn']
  const organization = rootGetters['membership/getOrganization']
  if (isUserSignedIn || isCorporateSignedIn) {
    // should add label when user login ex. membership_program_1, membership_rank_1, membership_organization_1
    if (pageLabel === 'place_search_result') {
      labelFilters.push({ labels: ['place_after_sign_in', 'all_users'] })
    }

    // Assign labels for the current rank
    labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel))
    labelFilters.push(assignMembershipLabelFilter(rootGetters, 'place_after_sign_in'))
    // Assign labels for the special "membership_rank_all"
    if (isUserSignedIn) {
      labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel, { rankAll: true }))
      labelFilters.push(
        assignMembershipLabelFilter(rootGetters, 'place_after_sign_in', { rankAll: true })
      )
    }
    if (isUserSignedIn && isCorporateSignedIn) {
      labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel, { ignoreRank: true }))
      labelFilters.push(
        assignMembershipLabelFilter(rootGetters, 'place_after_sign_in', { ignoreRank: true })
      )
    }
  }

  if (isCorporateSignedIn && organization.id) {
    labelFilters.push({
      labels: [
        pageLabel,
        'membership_organization_all',
        `membership_program_${organization.program_id}`
      ]
    })

    labelFilters.push({
      labels: [
        'place_after_sign_in',
        'membership_organization_all',
        `membership_program_${organization.program_id}`
      ]
    })
  }
  return labelFilters
}

export function generateHotelLabelFilters(pageLabel, rootGetters) {
  const labelFilters = [{ labels: [pageLabel, 'all_users'] }]
  const isUserSignedIn = rootGetters['membership/isUserSignedIn']
  const isCorporateSignedIn = rootGetters['membership/isCorporateSignedIn']
  const organization = rootGetters['membership/getOrganization']
  if (isUserSignedIn || isCorporateSignedIn) {
    // Assign labels for the current rank
    labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel))
    // Assign labels for the special "membership_rank_all"
    if (isUserSignedIn) {
      labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel, { rankAll: true }))
    }
    if (isUserSignedIn && isCorporateSignedIn) {
      labelFilters.push(assignMembershipLabelFilter(rootGetters, pageLabel, { ignoreRank: true }))
    }
  }

  if (isCorporateSignedIn && organization.id) {
    labelFilters.push({
      labels: [
        pageLabel,
        'membership_organization_all',
        `membership_program_${organization.program_id}`
      ]
    })
  }
  return labelFilters
}
