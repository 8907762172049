import VueRouter from 'vue-router'
import { appendCodeToRoute, hideChatbotSignIn } from './helpers'
import { isOta } from 'constants/tripla'
import paths from './paths'
import { decodeQuery, encodeQuery } from 'utilities/cipher'
import qs from 'qs'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'
import { OTA_ROUTES, otaRouterGuard } from './otaRoutes'
import { BW_ROUTES } from './bwRoutes'

if (window.location.href.includes('//?')) {
  const redirection = location.href.replace('//?', '/?')
  window.location = redirection
}

const routes = isOta ? OTA_ROUTES : BW_ROUTES
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === paths.reservation.guestDetail) {
      return { top: 0 }
    } else {
      return false
    }
  },
  parseQuery(raw) {
    return decodeQuery(qs.parse(raw))
  },
  ...(!isOta
    ? {
        stringifyQuery(raw) {
          const cleaned = _omitBy(raw, _isNil)
          return '?' + qs.stringify(encodeQuery(cleaned))
        }
      }
    : {})
})

if (isOta) {
  router.beforeEach(otaRouterGuard)
}

router.beforeEach((to, from, next) => {
  appendCodeToRoute(to, next)
  hideChatbotSignIn(to, from)
})

export default router
