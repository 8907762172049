import API from './fetch'
import { getCurrentPointURL, getPointHistory, getExchangeHistory, postExchange } from './url'
import store from 'store'

const Points = {
  get() {
    return API.getData(getCurrentPointURL())
  },
  getHistory(params) {
    const hotelId = store.getters['setting/getHotelId']
    return API.getData(getPointHistory(hotelId, params))
  },
  getExchangeHistory(page, perPage) {
    const hotelId = store.getters['setting/getHotelId']
    return API.getData(getExchangeHistory(hotelId, page, perPage))
  },
  postExchange(amount = 0, exchange_type = 'digico') {
    const payload = { amount, exchange_type }
    const hotelId = store.getters['setting/getHotelId']
    return API.postData(postExchange(hotelId), payload)
  }
}

export default Points
