import Cookies from 'js-cookie'

const sessionName = 'TRIPLA_CLIENT_SESSION'

export function setClientSession(session) {
  Cookies.set(sessionName, session, { expires: 18 / 24 })
}

export function getClientSession() {
  return Cookies.get(sessionName)
}

export function deleteClientSession() {
  return Cookies.remove(sessionName)
}
