<template>
  <div class="currency-btn" :class="{ selected: isSelected }" @click="$emit('click', currency)">
    <div class="d-flex flex-column">
      <span>{{ SUPPORT_MCP_CURRENCIES[currency].symbol || currency }}</span>
      <span :key="`${$i18n.locale}-${currency}`">
        {{ $t(`multipleCurrency.${currency}`) }}
      </span>
    </div>
    <i v-show="isSelected" class="icon-check" />
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { SUPPORT_MCP_CURRENCIES } from 'src/constants/currency'

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { $i18n } = getCurrentInstance().proxy

    return {
      SUPPORT_MCP_CURRENCIES,
      $i18n
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~stylesheets/vars.scss';

.currency-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  transition: 0.2s;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;

  .icon-check {
    font-size: 1.375rem;
    color: $tripla-link;
  }

  &:hover,
  &.selected {
    background-color: rgba($tripla-link, 0.2);
  }
}
</style>
