export default {
  root: 'Root',
  ota: {
    home: 'OtaHome',
    searchHotels: 'OtaSearchHotels',
    searchRooms: 'OtaSearchRooms',
    confirmReservation: 'OtaConfirmReservation',
    reservation: {
      index: 'Reservation',
      guestDetail: 'ReservationGuestDetail',
      payment: 'ReservationPayment',
      confirmation: 'ReservationConfirmation',
      review: 'ReservationReviewItem',
      failure: 'ReservationFailure'
    }
  },
  booking: {
    index: 'Booking',
    result: 'BookingResult',
    roomsRecommender: 'RoomsRecommender'
  },
  facilities: 'Facilities',
  cancellationPolicy: 'CancellationPolicy',
  roomLottery: {
    index: 'RoomLottery',
    guestDetail: 'RoomLotteryGuestDetail',
    confirmation: 'RoomLotteryConfirmation',
    complete: 'RoomLotteryConfirmationComplete',
    reservationIncomplete: 'RoomLotteryReservationIncomplete'
  },
  reservation: {
    index: 'Reservation',
    guestDetail: 'ReservationGuestDetail',
    confirmation: 'ReservationConfirmation',
    review: 'ReservationReviewItem',
    failure: 'ReservationFailure'
  },
  search: 'SearchReservation',
  membership: {
    signOut: 'SignOut',
    signIn: 'SignIn',
    signUp: 'SignUp',
    changePassword: 'ChangePassword',
    forgotPassword: 'ForgotPassword',
    memberConfirmation: 'Confirmation',
    memberDeletedConfirmation: 'Deleted',
    resendConfirmationEmail: 'ResendConfirmationEmail',
    otaSignIn: 'otaSignIn',
    chatSignIn: 'chatSignIn',
    chatSignUp: 'chatSignUp',
    chatSignUpForm: 'chatSignUpForm',
    chatSignUpResult: 'ChatSignUpResult',
    chatSignUpSyncDone: 'ChatSignUpSyncDone',
    signUpResult: 'SignUpResult',
    signUpSyncDone: 'SignUpSyncDone'
  },
  organization: {
    signIn: 'OrganizationSignIn',
    admin: 'OrganizationAdmin'
  },
  myAccount: {
    profile: 'MyProfile',
    reservations: 'MyReservations',
    points: 'MyPoints',
    paymentOptions: 'paymentOptions',
    promotions: 'MyPromotions'
  },
  iframeToken: 'IframeToken',
  iframeLiffToken: 'IframeLiffToken',
  iframeChangeLocale: 'IframeChangeLocale',
  unsubscribeEmailConfirmation: 'UnsubscribeEmailConfirmation',
  unsubscribeEmailSuccess: 'UnsubscribeEmailSuccess',
  triplaPay: 'TriplaPay',
  triplaPayDisplay: 'TriplaPayDisplay',
  errorPages: {
    serviceUnavailable: 'ErrorPageServiceUnavailable'
  },
  recommenderWidget: 'RecommenderWidget'
}
