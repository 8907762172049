const state = {
  receipt_title: '',
  receipt_unicode: ''
}

const getters = {
  getReceiptInfo: (state) => state
}

const mutations = {
  setReceiptInfo: (state, { receipt_title, receipt_unicode }) => {
    state.receipt_title = receipt_title
    state.receipt_unicode = receipt_unicode
  }
}

const actions = {
  setReceiptInfo: ({ commit }, payload) => {
    commit('setReceiptInfo', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
