<template>
  <b-alert
    :show="show"
    :variant="variant"
    :dismissible="dismissible"
    @dismissed="$emit('dismissed')"
  >
    <ul class="error-list">
      <li v-for="(error, index) in errorList" :key="index">
        <template v-if="Array.isArray(error)">
          <p
            v-for="(errorItem, errorIndex) in error"
            :key="errorIndex"
            v-linkified
            class="mb-0"
            v-html="getErrorMessage(errorItem)"
          />
        </template>
        <p v-else v-linkified class="mb-0" v-html="getErrorMessage(error)" />
      </li>
    </ul>
  </b-alert>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import linkify from 'vue-linkify'
import _get from 'lodash/get'
import { TRANSLATED_ERROR_CODES } from 'constants/errorCode'

export default {
  name: 'FormError',
  directives: { linkified: linkify },
  props: {
    errors: { type: [Array, String, Error], default: null },
    variant: { type: String, default: 'danger' },
    dismissible: { type: Boolean, default: false }
  },
  computed: {
    show() {
      return !_isEmpty(this.errors)
    },
    errorList() {
      if (!this.errors) return []

      const errorsInDetailsBase = _get(this.errors, 'json.errors[0].details.base')

      if (Array.isArray(errorsInDetailsBase))
        return errorsInDetailsBase.map(({ code, error: errorMessage }) =>
          TRANSLATED_ERROR_CODES.includes(code) ? this.$t(`base.errorCode.${code}`) : errorMessage
        )

      return Array.isArray(this.errors) ? this.errors : [this.errors]
    }
  },
  methods: {
    getErrorMessage(error) {
      if (typeof error === 'string') return error

      return this.getFirstTruthyProperty(error)
    },
    getFirstTruthyProperty(error) {
      const validErrorProperties = ['title', 'message', 'titleList']

      for (const property of validErrorProperties) {
        if (error?.hasOwnProperty(property)) return error[property]
      }

      return error
    }
  }
}
</script>

<style lang="scss" scoped>
ul.error-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  & > li > p {
    overflow-wrap: break-word;
  }
}
</style>
