import API from './fetch'
import {
  reservationURL,
  reservationConfirmURL,
  reservationCancelURL,
  searchReservationURL,
  reservationGuestURL,
  searchReservationByUserURL,
  additionalQuestionURL,
  editAdditionalQuestionURL,
  additionalQuestionListURL,
  extrasURL,
  updateReservationURL,
  brandAdditionalQuestionURL,
  reservationReceiptURL,
  reservationPaypalClientTokenURL,
  reservationPaypalOrderURL,
  reservationValidateCreditCardURL,
  storageURL,
  restoreStorageUrl,
  patchPaymentHubTransactionURL
} from './url'
import { appendParams } from 'utilities/paramsFormat'

const Reservations = {
  cancel({ reservationID, email, token, hotelId, reason, details, cancellation_fee_displayed }) {
    return API.putData(reservationCancelURL({ reservationID, email, token, hotelId }), {
      reason,
      details,
      cancellation_fee_displayed
    })
  },
  create(data) {
    return API.postData(reservationURL(), data)
  },
  storageGuestData(data) {
    return API.postData(storageURL(), { data })
  },
  getStorageData(storageKey) {
    return API.getData(restoreStorageUrl(storageKey))
  },
  get({ reservationID, query, hotelId }) {
    return API.getData(appendParams(reservationConfirmURL({ reservationID, hotelId }), query))
  },
  update(reservationID, query, payload) {
    return API.putData(appendParams(reservationConfirmURL({ reservationID }), query), payload)
  },
  search(data) {
    return API.getData(searchReservationURL(data.reservationNumber, data.email))
  },
  updateGuest({ reservationID, hotelId }, data) {
    return API.putData(reservationGuestURL({ reservationID, hotelId }), data)
  },
  searchByUser({ page = 1, status = 'upcoming', ...rest }) {
    return API.getData(searchReservationByUserURL({ ...rest, page, status }))
  },
  updateAdditionalAnswers({ reservationID, hotelId }, data) {
    return API.putData(editAdditionalQuestionURL({ reservationID, hotelId }), data)
  },
  postAdditionalAnswers(reservationID, data) {
    return API.postData(additionalQuestionURL(reservationID), data)
  },
  getAdditionalQuestions({ reservationID, hotelId }, query) {
    return API.getData(appendParams(additionalQuestionURL({ reservationID, hotelId }), query))
  },
  getAdditionalQuestionList(params) {
    return API.getData(appendParams(additionalQuestionListURL(), params))
  },
  getBrandAdditionalQuestions(params) {
    return API.getData(appendParams(brandAdditionalQuestionURL(), params))
  },
  getExtras(params) {
    return API.getData(appendParams(extrasURL(), params))
  },
  getUpdateSetting({ reservationID, hotelId }, params) {
    return API.getData(appendParams(updateReservationURL({ reservationID, hotelId }), params))
  },
  downloadReceipt(params) {
    return API.getData(reservationReceiptURL(params))
  },
  getPaypalClientToken() {
    return API.postData(reservationPaypalClientTokenURL(), {})
  },
  getPaypalOrder(payload) {
    return API.postData(reservationPaypalOrderURL(), payload)
  },
  validateCreditCard(payload) {
    return API.postData(reservationValidateCreditCardURL(), payload)
  },
  updateTransactionToken({ reservationID, hotelId, transactionId }, payload) {
    return API.patchData(
      patchPaymentHubTransactionURL({ reservationID, hotelId, transactionId }),
      payload
    )
  }
}

export default Reservations
