import snakeCase from 'lodash.snakecase'

export function appendParams(urlString, appendHash) {
  if (!urlString) return urlString
  const urlObject = new URL(urlString)
  const originalURL = urlObject.origin + urlObject.pathname
  const urlParamsObject = parseParamsURL(urlString)
  const allParams = { ...urlParamsObject, ...appendHash }

  let paramsArray = []
  Object.keys(allParams).forEach((key) => {
    if (allParams[key] === null || allParams[key] === undefined) return
    if (Array.isArray(allParams[key])) {
      const arrayToConcat = parseArray(allParams[key], snakeCase(key))
      paramsArray = paramsArray.concat(arrayToConcat)
    } else if (typeof allParams[key] === 'object') {
      const arrayToConcat = parseObject(allParams[key], snakeCase(key))
      paramsArray = paramsArray.concat(arrayToConcat)
    } else {
      const queryKey = snakeCase(key)
      const queryValue = allParams[key]
      return paramsArray.push(`${encodeURIComponent(queryKey)}=${encodeURIComponent(queryValue)}`)
    }
  })

  return `${originalURL}?${paramsArray.join('&')}`
}

export function setAppType(store, params) {
  const fromChatbot = store.getters['setting/isInChatbotIframe']
  if (fromChatbot) params['app_type'] = 'triplabot'
}

function parseArray(arr, key) {
  let resultString = []
  arr.forEach((item) => {
    if (typeof item === 'object') {
      resultString = resultString.concat(parseObject(item, `${key}[]`))
    } else {
      const objectString = `${key}[]=${encodeURIComponent(item)}`
      resultString.push(objectString)
    }
  })
  return resultString
}

function parseObject(obj, key) {
  let resultString = []
  Object.keys(obj).forEach((item) => {
    if (Array.isArray(obj[item])) {
      resultString = resultString.concat(parseArray(obj[item], `${key}[${item}]`))
    } else {
      const objectString = `${key}[${item}]=${encodeURIComponent(obj[item])}`
      resultString.push(objectString)
    }
  })
  return resultString
}

export function parseParamsURL(urlHashString) {
  const result = {}
  urlHashString.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    result[key] = decodeURIComponent(value)
  })
  return result
}

export function checkIsUnsubscribeEmailPath(pathName) {
  const confirmationPath = '/unsubscribe-email/confirmation'
  const successPath = '/unsubscribe-email/success'

  return [confirmationPath, successPath].includes(pathName)
}

export function generateParamsURL(originalURL, data, arrayList) {
  const url = new URL(originalURL)
  Object.keys(data).forEach((key) => {
    if (arrayList.indexOf(key) < 0) {
      url.searchParams.append(key, data[key])
    } else {
      if (data[key]) appendArrayQueryString(url, data[key], key)
    }
  })
  return url.href
}

export function generateForSelectedRooms(originalURL, data, arrayList) {
  const params = []
  let arrayParams = []
  Object.keys(data).forEach((key) => {
    if (arrayList.indexOf(key) < 0) {
      params.push(`${key}=${data[key]}`)
    } else {
      if (data[key]) arrayParams = appendArrayStringForSelectedRoom(data[key], key)
    }
  })
  const allParams = params.concat(arrayParams).join('&')
  return `${originalURL}?${allParams}`
}

function appendArrayStringForSelectedRoom(queryItem, backendQueryName) {
  const itemList = Array.isArray(queryItem) ? queryItem : [queryItem]
  const params = []
  for (let i = 0; i < itemList.length; i++) {
    const item = itemList[i]
    if (typeof item !== 'object') params.push(`${backendQueryName}[]=${item}`)
    else {
      Object.keys(item).forEach((perRoomAttribute) => {
        if (Array.isArray(item[perRoomAttribute])) {
          item[perRoomAttribute].forEach((age) => {
            params.push(`${backendQueryName}[][${perRoomAttribute}][]=${age}`)
          })
        } else {
          params.push(`${backendQueryName}[][${perRoomAttribute}]=${item[perRoomAttribute]}`)
        }
      })
    }
  }
  return params
}

function appendArrayQueryString(url, queryItem, backendQueryName) {
  const itemList = Array.isArray(queryItem) ? queryItem : [queryItem]
  for (let i = 0; i < itemList.length; i++) {
    const item = itemList[i]
    if (typeof item === 'object') {
      Object.keys(item).forEach((perRoomAttribute) => {
        if (Array.isArray(item[perRoomAttribute])) {
          item[perRoomAttribute].forEach((age) => {
            url.searchParams.append(`${backendQueryName}[][${perRoomAttribute}][]`, age)
          })
        } else {
          url.searchParams.append(
            `${backendQueryName}[][${perRoomAttribute}]`,
            item[perRoomAttribute]
          )
        }
      })
    } else {
      url.searchParams.append(`${backendQueryName}[]`, item)
    }
  }
}

/**
 * Get full path based on current location
 *
 * @author Sahat Yalkabov <https://github.com/sahat>
 * @copyright Method taken from https://github.com/sahat/satellizer
 *
 * @param  {Location} location
 * @return {String}
 */
export function getFullUrlPath(location) {
  const isHttps = location.protocol === 'https:'
  return (
    location.protocol +
    '//' +
    location.hostname +
    ':' +
    (location.port || (isHttps ? '443' : '80')) +
    (/^\//.test(location.pathname) ? location.pathname : '/' + location.pathname)
  )
}

/**
 * Parse query string variables
 *
 * @author Sahat Yalkabov <https://github.com/sahat>
 * @copyright Method taken from https://github.com/sahat/satellizer
 *
 * @param  {String} Query string
 * @return {String}
 */
export function parseQueryString(str) {
  const obj = {}
  let key
  let value
  ;(str || '').split('&').forEach((keyValue) => {
    if (keyValue) {
      value = keyValue.split('=')
      key = decodeURIComponent(value[0])
      obj[key] = value[1] ? decodeURIComponent(value[1]) : true
    }
  })
  return obj
}
