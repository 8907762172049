<template>
  <div>
    <button
      v-b-modal.multiple-currency-modal
      class="navbar-button px-2 mr-1"
      data-cy="currency-selector-btn"
    >
      {{ SUPPORT_MCP_CURRENCIES[selectedCurrency].symbol }}
    </button>

    <b-modal
      id="multiple-currency-modal"
      :key="$i18n.locale"
      size="custom"
      hide-footer
      static
      centered
    >
      <template #modal-title>{{ $t('multipleCurrency.modalTitle') }}</template>
      <p>{{ $t('multipleCurrency.modalDescription') }}</p>

      <div class="content">
        <h6>{{ $t('multipleCurrency.suggestedCurrencies') }}</h6>

        <div class="currency-list">
          <multiple-currency-option-button
            v-for="currency in suggestedCurrencyList"
            :key="`suggestion-${currency}`"
            :currency="currency"
            :data-cy="`suggestion-${currency}`"
            @click="selectCurrency(currency)"
          />
        </div>

        <h6>{{ $t('multipleCurrency.allCurrencies') }}</h6>

        <div class="currency-list">
          <multiple-currency-option-button
            v-for="currency in currencyList"
            :key="`all-${currency}`"
            :currency="currency"
            :is-selected="currency === selectedCurrency"
            :data-cy="`all-${currency}`"
            @click="selectCurrency(currency)"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { SUPPORT_MCP_CURRENCIES } from 'constants/currency'
import store from 'src/store'
import MultipleCurrencyOptionButton from './MultipleCurrencyOptionButton.vue'

export default defineComponent({
  name: 'MultipleCurrencyModal',
  components: { MultipleCurrencyOptionButton },
  setup() {
    const allCurrencies = Object.keys(SUPPORT_MCP_CURRENCIES)
    const { $t } = getCurrentInstance().proxy

    const selectedCurrency = computed(() => store.getters['mcp/selectedCurrency'])

    const currencyList = computed(() => {
      const filteredCurrencies = allCurrencies.filter(
        (currency) => currency !== selectedCurrency.value
      )

      filteredCurrencies.sort((a, b) => {
        const aText = $t(`multipleCurrency.${a}`)
        const bText = $t(`multipleCurrency.${b}`)
        return aText.localeCompare(bText)
      })

      filteredCurrencies.unshift(selectedCurrency.value)

      return filteredCurrencies
    })

    const suggestedCurrencyList = computed(() =>
      allCurrencies.filter((currency) => {
        return SUPPORT_MCP_CURRENCIES[currency].isSuggested && currency !== selectedCurrency.value
      })
    )

    function selectCurrency(currency) {
      store.dispatch('mcp/setSelectedCurrency', currency)
      this.$bvModal.hide('multiple-currency-modal')
    }

    return {
      SUPPORT_MCP_CURRENCIES,
      currencyList,
      suggestedCurrencyList,
      selectedCurrency,
      selectCurrency
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~stylesheets/vars.scss';
@import '~stylesheets/mixins.scss';

.currency-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

::v-deep(#multiple-currency-modal) {
  overflow: hidden;
}

.content {
  max-height: 50vh;
  overflow: scroll;
}

h6 {
  font-weight: bold;
  margin: 12px 0;
}

::v-deep(.modal .modal-custom) {
  max-width: 90%;
  width: 960px;
}
</style>
