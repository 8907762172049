import { mapGetters } from 'vuex'
import paths from 'routes/paths'
import { generateSearchForm, genQueryParamsResultPage } from 'utilities/generateSearchForm'

export default {
  computed: {
    ...mapGetters({
      searchForm: 'search/getSearchForm',
      isTiersMode: 'setting/isTiersMode',
      isBrandFacility: 'setting/isBrandFacility'
    })
  },
  methods: {
    goToSearchPage(queryParams = {}) {
      if (!this.searchForm || !this.searchForm.checkin) {
        // Generate default search form
        const defaultSearchFormData = generateSearchForm(this.searchForm || {})
        this.$store.dispatch('search/setSearchForm', defaultSearchFormData)
      }
      const query = genQueryParamsResultPage(this.searchForm, this.isTiersMode)
      if (this.isBrandFacility) {
        this.$router.push({ name: paths.facilities, query: { ...query, ...queryParams } })
      } else {
        this.$router.push({ name: paths.booking.result, query: { ...query, ...queryParams } })
      }
    }
  }
}
