import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isToday from 'dayjs/plugin/isToday'
import i18n from '../i18n'
// import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'

// notes about timezone:
//    1. Date objects should always be considered local timezone, need change timezone before formatted.
//    2. date strings should always be parsed with hotel timezone
//       dayjs(DateString).tz()  =>  DateString will be parsed with local timezone. <--- DONT DO THIS
//       dayjs.tz(DateString)    =>  DateString will be parsed with hotel timezone.
//
// PS: We should use YYYY-MM-DD (ISO 8601) instead of YYYY/MM/DD (Sinosphere common order)
//     https://en.wikipedia.org/wiki/Date_format_by_country
//

dayjs.extend(utc) // required by timezone
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)
// dayjs.extend(localizedFormat)

let browserLanguage = i18n.locale

if (browserLanguage === 'zh_Hans') {
  browserLanguage = 'zh-cn'
} else if (browserLanguage === 'zh_Hant') {
  browserLanguage = 'zh-tw'
}

export const localeDateMapping = {
  en: 'en',
  ja: 'ja',
  zh_Hans: 'zh-cn',
  zh_Hant: 'zh-tw',
  ko: 'ko'
}

export function formatPrettier(date, locale) {
  return new Intl.DateTimeFormat(localeDateMapping[locale], {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  }).format(new Date(date))
}

// parse any input, return zoned Date() instance, use stringFormat if raw is a string but not zoned
export const parse = (raw, stringFormat) => {
  if (raw instanceof Date) return raw
  if (raw instanceof dayjs) return raw.toDate()

  // zoned ISO-8601
  if (/^[\d-WT:]+(?:Z|\+[\d:]+)$/.test(raw)) return new Date(raw)

  if (typeof raw === 'string') {
    const localDate = dayjs(raw, stringFormat)
    const localDateStr = localDate.isValid() ? localDate.format('YYYY-MM-DD HH:mm:ss') : raw
    return dayjs.tz(localDateStr).toDate()
  }

  return new Date(raw)
}

export const currentYear = () => dayjs.tz().year()
export const currentMonth = () => dayjs.tz().month()

export const diffToday = (target, format = 'YYYY-MM-DD') => {
  const date1 = dayjs.tz()
  const date2 = dayjs(target, format)
  return date2.diff(date1, 'day')
}

export const diff = (from, to, fromFormat = 'YYYY-MM-DD', toFormat = 'YYYY-MM-DD') => {
  const date1 = dayjs(from, fromFormat)
  const date2 = dayjs(to, toFormat)
  if (!date1.isValid() || !date2.isValid()) {
    return 0
  }
  return date2.diff(date1, 'day')
}

export const setTimezone = (newTz) => {
  try {
    dayjs.tz.setDefault(newTz)
  } catch {
    // pass
  }
}

export function dateOfToday(format = 'YYYY/MM/DD') {
  return dayjs.tz().format(format)
}

export function parseDate(string, format = 'YYYY/MM/DD', locale = 'en', strictParsing = true) {
  const localDate = dayjs(string, format, locale, strictParsing)
  return localDate.isValid() ? dayjs.tz(localDate.format('YYYY-MM-DD')) : localDate
}

export function formatDate02(value, format, locale) {
  if (!value) return ''
  if (!locale) locale = browserLanguage
  const dateValue = dayjs.tz(value).locale(locale)
  return dateValue.format(format)
}

export function offsetFormatDate(value, format, locale) {
  if (!value) return ''
  if (!locale) locale = browserLanguage
  const dateValue = dayjs.tz(value).subtract(1, 'second').locale(locale)
  return dateValue.format(format)
}

export function formatDate(date, format = 'yyyy/mm/dd') {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const formattedDate = format
    .replace(/dd/, ('0' + day).slice(-2))
    .replace(/d/, day)
    .replace(/yyyy/, year)
    .replace(/yy/, String(year).slice(2))
    .replace(/mm/, ('0' + month).slice(-2))
    .replace(/m(?!a|ä|e)/, month)
  return formattedDate
}

export function findDayAfterToday(day = 0, format = 'YYYY/MM/DD') {
  return dayjs.tz().add(day, 'day').format(format)
}

export function addDay(date, day, outputFormat = 'YYYY/MM/DD', inputFormat = 'YYYY/MM/DD') {
  return parseDate(date, inputFormat).add(day, 'day').format(outputFormat)
}

export function isDatePast(date, format = 'YYYY/MM/DD', unit) {
  return dayjs.tz().isAfter(parseDate(date, format), unit)
}

export function isDateAfter(date, format = 'YYYY/MM/DD') {
  return dayjs.tz().isAfter(parseDate(date, format), 'day')
}

export function isDateToday(date) {
  return dayjs.tz(date).isToday()
}

export function compareDateAfter(date, target, format = 'YYYY/MM/DD', unit = 'day') {
  return dayjs.tz(parseDate(date, format)).isAfter(parseDate(target, format), unit)
}

export function compareDateBefore(date, target, format = 'YYYY/MM/DD', unit = 'day') {
  return dayjs.tz(parseDate(date, format)).isBefore(parseDate(target, format), unit)
}

export function isBeforeThatMonth(date1, date2, format = 'YYYY/MM/DD') {
  const endDayOfMonth = dayjs(date2, 'YYYY/MM').daysInMonth()
  return parseDate(date1, format).isBefore(parseDate(`${date2}/${endDayOfMonth}`, format))
}

export function now(format) {
  return dayjs.tz().format(format ?? undefined)
}

export function generateRange(from, to, includeLast = true) {
  const duration = to.diff(from, 'day')
  const result = []

  for (let index = 0; index < duration; index++) {
    const newDate = from.add(index, 'day')
    result.push(newDate)
  }
  if (includeLast) result.push(to)
  return result
}
