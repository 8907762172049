import getServerUrl from 'api/domain'

const chatbotScriptMapping = {
  staging: 'https://s3-ap-northeast-1.amazonaws.com/triplabot-staging/tripla.min.js',
  qa: 'https://s3-ap-northeast-1.amazonaws.com/triplabot-qa/tripla.min.js',
  development: `${process.env.LOCAL_CHATBOT_URL || 'http://localhost:8081'}/dist/tripla.min.js`
}

export function getChatbotScriptUrl() {
  const host = location.host
  let url = 'https://tripla.jp/sdk/javascript/tripla.min.js'

  if (chatbotScriptMapping[TARGET_ENV]) {
    url = chatbotScriptMapping[TARGET_ENV]
  } else {
    if (host.includes('staging')) {
      url = chatbotScriptMapping.staging
    } else if (host.includes('qa')) {
      url = chatbotScriptMapping.qa
    }
  }
  return url
}

export function appendChatbot(code) {
  if (!code || document.getElementById('triplabot-script') || document.getElementById('tripla-app'))
    return

  const script = document.createElement('script')
  script.src = getChatbotScriptUrl()
  script.id = 'triplabot-script'
  script.setAttribute('data-triplabot-mode', 'booking-widget')
  script.setAttribute('data-triplabot-code', code)
  script.setAttribute('data-triplabot-base-url', getServerUrl() + '/')
  script.setAttribute('data-triplabot-draggable', 'true')

  document.body.appendChild(script)
}
