import { RoomLottery } from 'api/RoomLottery'

const state = {
  lotteryEventSetting: {},
  isLotteryEntry: false
}

const getters = {
  // Lottery events have two phases: entry and booking.
  // getIsLotteryEntry and getIsLotteryBooking should NOT be true at the same time.
  getIsLotteryEntry: (state) => state.isLotteryEntry,
  getIsLotteryBooking: (state, getters) => false,
  getLotteryEventSetting: (state) => state.lotteryEventSetting,
  getNotification: (state) => state.lotteryEventSetting?.notification
}

const mutations = {
  setIsLotteryEntry: (state, payload) => {
    state.isLotteryEntry = payload
  },
  setLotteryEventSetting: (state, payload) => {
    state.lotteryEventSetting = payload
  }
}

const actions = {
  setIsLotteryEntry({ commit }, payload) {
    commit('setIsLotteryEntry', payload)
  },
  setLotteryEventSetting({ commit }, payload) {
    commit('setLotteryEventSetting', payload)
  },
  async fetchLotteryEvent({ commit }, lotteryEventUuid) {
    const { data: lotteryEventSetting } = await RoomLottery.getLotteryEventSetting(lotteryEventUuid)
    commit('setLotteryEventSetting', lotteryEventSetting)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
