import store from 'store'

export default function getServerUrl(product = 'book') {
  const postfix = process.env.NODE_ENV === 'development' ? '' : `/${product}`

  const { baseURL } = store.getters['setting/getSetting']
  if (baseURL) return `${decodeURIComponent(baseURL)}${postfix}`

  return `${process.env.API_URL}${postfix}`
}

export const getIdentityServerUrl = (product = 'book') => `${process.env.IDENTITY_URL}/${product}`
