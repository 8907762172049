import { asyncComponentLoader } from './helpers'
import paths from './paths'

export const BW_ROUTES = [
  {
    path: '/payments/:paymentNumber',
    name: paths.triplaPay,
    component: asyncComponentLoader('TriplaPay', 'show'),
    meta: { hideChatbot: true, triplaPay: true }
  },
  {
    path: '/payments/:paymentNumber/display',
    name: paths.triplaPayDisplay,
    component: asyncComponentLoader('TriplaPay', 'display'),
    meta: { hideChatbot: true, triplaPayDisplay: true }
  },
  {
    path: '/iframe-token',
    name: paths.iframeToken,
    component: asyncComponentLoader('IframeToken')
  },
  {
    path: '/iframe-liff-token',
    name: paths.iframeLiffToken,
    component: asyncComponentLoader('IframeLiffToken')
  },
  {
    path: '/iframe-change-locale',
    name: paths.iframeChangeLocale,
    component: asyncComponentLoader('IframeChangeLocale')
  },
  {
    path: '/unsubscribe-email/confirmation',
    name: paths.unsubscribeEmailConfirmation,
    component: asyncComponentLoader('UnsubscribeEmail', 'Confirmation'),
    meta: { closeButtonOnlyHeader: true }
  },
  {
    path: '/unsubscribe-email/success',
    name: paths.unsubscribeEmailSuccess,
    component: asyncComponentLoader('UnsubscribeEmail', 'Success'),
    meta: { closeButtonOnlyHeader: true }
  },
  {
    path: '/recommender-widget',
    name: paths.recommenderWidget,
    component: asyncComponentLoader('RecommenderWidget')
  },
  {
    path: '/',
    component: asyncComponentLoader('AuthorizedRouterView'),
    children: [
      {
        path: '',
        redirect: { name: paths.booking.result }
      },
      {
        path: 'booking',
        name: paths.booking,
        component: asyncComponentLoader('Booking'),
        children: [
          {
            path: 'result',
            name: paths.booking.result,
            component: asyncComponentLoader('Booking', 'Result'),
            meta: {
              protected: false,
              pageTitle: 'search_results'
            }
          },
          {
            path: 'recommender',
            name: paths.booking.roomsRecommender,
            component: asyncComponentLoader('Booking', 'Recommender'),
            meta: {
              protected: false,
              pageTitle: 'search_results'
            }
          }
        ]
      },
      {
        path: 'facilities',
        name: paths.facilities,
        component: asyncComponentLoader('Facilities'),
        meta: {
          protected: false,
          pageTitle: 'facilities'
        }
      },
      {
        path: 'cancellation-policy',
        name: paths.cancellationPolicy,
        component: asyncComponentLoader('CancellationPolicy'),
        meta: {
          protected: false
        }
      },
      {
        path: 'room-lottery',
        name: paths.roomLottery.index,
        component: asyncComponentLoader('RoomLottery'),
        children: [
          {
            path: 'guest-detail',
            name: paths.roomLottery.guestDetail,
            component: asyncComponentLoader('RoomLottery', 'GuestDetail'),
            meta: {
              protected: true,
              pageTitle: 'guest_detail'
            }
          },
          {
            path: 'confirmation/:confirmationNumber',
            name: paths.roomLottery.confirmation,
            component: asyncComponentLoader('RoomLottery', 'Confirmation'),
            meta: {
              protected: true,
              pageTitle: 'lotteryCompleted'
            }
          },
          {
            path: 'complete/:confirmationNumber',
            name: paths.roomLottery.complete,
            component: asyncComponentLoader('RoomLottery', 'Complete'),
            meta: {
              protected: false,
              pageTitle: 'lotteryCompleted'
            }
          },
          {
            path: 'reservation-incomplete/:lotteryEntryNumber',
            name: paths.roomLottery.reservationIncomplete,
            component: asyncComponentLoader('RoomLottery', 'ReservationIncomplete'),
            meta: {
              protected: false,
              pageTitle: 'reservationIncomplete'
            }
          }
        ]
      },
      {
        path: 'reservation',
        name: paths.reservation.index,
        component: asyncComponentLoader('Reservation'),
        children: [
          {
            path: 'guest-detail',
            name: paths.reservation.guestDetail,
            component: asyncComponentLoader('Reservation', 'BookingDetail'),
            meta: {
              protected: false,
              pageTitle: 'guest_detail'
            }
          },
          {
            path: 'confirmation/:confirmationNumber',
            name: paths.reservation.confirmation,
            component: asyncComponentLoader('Reservation', 'Confirmation'),
            meta: {
              protected: false,
              pageTitle: 'completed'
            },
            props: (route) => ({
              confirmationNumber: route.params.confirmationNumber,
              email: route.query.email,
              bypass_token: route.query.bypass_token,
              code: route.code,
              from: route.params.from
            })
          },
          {
            path: 'fail',
            name: paths.reservation.failure,
            component: asyncComponentLoader('Reservation', 'Failure'),
            meta: {
              protected: false
            },
            props: (route) => ({
              code: route.query.code,
              checkin: route.query.checkin,
              checkout: route.query.checkout,
              adults: route.query.adults,
              errorCode: route.query.error_code,
              error: route.query.error
            })
          }
        ]
      },
      {
        path: 'review-reservation/:confirmationNumber',
        name: paths.reservation.review,
        component: asyncComponentLoader('Reservation', 'Confirmation'),
        meta: {
          protected: true,
          pageTitle: 'completed'
        },
        props: (route) => ({
          confirmationNumber: route.params.confirmationNumber,
          hotelId: route.query.hotelId,
          code: route.code
        })
      },
      {
        path: 'organization-sign-in',
        name: paths.organization.signIn,
        component: asyncComponentLoader('Organization', 'SignIn'),
        meta: {
          protected: false,
          pageTitle: 'organization_sign_in'
        }
      },
      {
        path: 'organization-admin',
        name: paths.organization.admin,
        component: asyncComponentLoader('Organization', 'Admin'),
        meta: {
          protected: true
        }
      },
      {
        path: 'ota-sign-in',
        name: paths.membership.otaSignIn,
        component: asyncComponentLoader('Membership', 'OtaSignIn'),
        meta: {
          protected: false,
          pageTitle: 'sign_in'
        }
      },
      {
        path: 'sign-in',
        name: paths.membership.signIn,
        component: asyncComponentLoader('Membership', 'SignIn'),
        meta: {
          protected: false,
          pageTitle: 'sign_in'
        }
      },
      {
        path: 'chat-sign-in',
        name: paths.membership.chatSignIn,
        component: asyncComponentLoader('Membership', 'ChatSignIn'),
        meta: {
          closeButtonOnlyHeader: true,
          protected: false,
          pageTitle: 'sign_in',
          contentStyle: { background: 'white' }
        }
      },
      {
        path: 'sign-out',
        name: paths.membership.signOut,
        component: asyncComponentLoader('Membership', 'SignOut'),
        meta: {
          protected: true
        }
      },
      {
        path: 'sign-up',
        name: paths.membership.signUp,
        component: asyncComponentLoader('Membership', 'SignUp'),
        meta: {
          protected: false,
          pageTitle: 'sign_up'
        },
        props: true
      },
      {
        path: 'sign-up-result',
        name: paths.membership.signUpResult,
        component: asyncComponentLoader('Membership', 'SignUpResult'),
        meta: {
          protected: false,
          pageTitle: 'sign_up'
        }
      },
      {
        path: 'sign-up-done',
        name: paths.membership.signUpSyncDone,
        component: asyncComponentLoader('Membership', 'SignUpSyncDone'),
        meta: {
          protected: false,
          pageTitle: 'sign_up'
        }
      },
      {
        path: 'chat-sign-up-result',
        name: paths.membership.chatSignUpResult,
        component: asyncComponentLoader('Membership', 'SignUpResult'),
        meta: {
          closeButtonOnlyHeader: true,
          protected: false,
          pageTitle: 'sign_up',
          contentStyle: { background: 'white' }
        }
      },
      {
        path: 'chat-sign-up-done',
        name: paths.membership.chatSignUpSyncDone,
        component: asyncComponentLoader('Membership', 'SignUpSyncDone'),
        meta: {
          closeButtonOnlyHeader: true,
          protected: false,
          pageTitle: 'sign_up',
          contentStyle: { background: 'white' }
        }
      },
      {
        path: 'chat-sign-up',
        name: paths.membership.chatSignUp,
        component: asyncComponentLoader('Membership', 'ChatSignUp'),
        meta: {
          closeButtonOnlyHeader: true,
          protected: false,
          pageTitle: 'sign_up',
          contentStyle: { background: 'white' }
        },
        props: true
      },
      {
        path: 'chat-sign-up-form',
        name: paths.membership.chatSignUpForm,
        component: asyncComponentLoader('Membership', 'ChatSignUpForm'),
        meta: {
          closeButtonOnlyHeader: true,
          protected: false,
          pageTitle: 'sign_up',
          contentStyle: { background: 'white' }
        },
        props: true
      },
      {
        path: 'forgot-password',
        name: paths.membership.forgotPassword,
        component: asyncComponentLoader('Membership', 'ForgotPassword'),
        meta: {
          protected: false
        }
      },
      {
        path: 'change-password',
        name: paths.membership.changePassword,
        component: asyncComponentLoader('Membership', 'ChangePassword'),
        meta: {
          protected: false
        },
        props: (route) => ({
          token: route.query.reset_password_token,
          displayHeader: true
        })
      },
      {
        path: 'search',
        name: paths.search,
        component: asyncComponentLoader('SearchReservation'),
        meta: {
          protected: false
        },
        props: (route) => ({
          code: route.query.code
        })
      },
      {
        path: 'member-confirmation',
        name: paths.membership.memberConfirmation,
        component: asyncComponentLoader('Membership', 'Confirmation'),
        props: (route) => ({
          token: route.query.confirmation_token,
          email_update: route.query.email_update,
          redirect_url: route.query.redirect_url || '',
          program_id: route.query.program_id,
          displayHeader: true
        })
      },
      {
        path: 'my-account',
        props: (route) => ({
          displayHeader: true
        }),
        meta: {
          protected: true,
          customBackground: true
        },
        component: asyncComponentLoader('MyAccount'),
        children: [
          {
            path: '',
            redirect: { name: paths.myAccount.profile }
          },
          {
            path: 'profile',
            name: paths.myAccount.profile,
            component: asyncComponentLoader('MyAccount', 'MyProfileForm'),
            meta: { pageTitle: 'my_account' }
          },
          {
            path: 'reservations',
            name: paths.myAccount.reservations,
            component: asyncComponentLoader('MyAccount', 'MyReservations'),
            meta: { pageTitle: 'my_account' }
          },
          {
            path: 'points',
            name: paths.myAccount.points,
            component: asyncComponentLoader('MyAccount', 'PointHistory'),
            meta: { pageTitle: 'my_account' }
          },
          {
            path: 'payment-options',
            name: paths.myAccount.paymentOptions,
            component: asyncComponentLoader('MyAccount', 'PaymentOptions'),
            meta: { pageTitle: 'my_account' }
          },
          {
            path: 'promotions',
            name: paths.myAccount.promotions,
            component: asyncComponentLoader('MyAccount', 'MyPromotions'),
            meta: { pageTitle: 'my_account' }
          }
        ]
      },
      {
        path: 'member-deleted-confirmation',
        name: paths.membership.memberDeletedConfirmation,
        component: asyncComponentLoader('Membership', 'Deleted')
      },
      {
        path: 'resend-confirmation-email',
        name: paths.membership.resendConfirmationEmail,
        component: asyncComponentLoader('Membership', 'ResendConfirmationEmail'),
        meta: {
          protected: false
        }
      },
      {
        path: '503',
        name: paths.errorPages.serviceUnavailable,
        component: asyncComponentLoader('ErrorPages', 'ServiceUnavailable'),
        meta: {
          protected: false,
          ignoreError: true
        }
      }
    ]
  }
]
