<template>
  <footer id="tripla-footer-container" ref="footer" v-dompurify-html="footerHTML" />
</template>

<script>
import { appendStyleInHead } from 'utilities/domOperation'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomizedFooter',
  computed: {
    ...mapGetters({
      footerHTML: 'setting/getFooter',
      footerCSS: 'setting/getFooterCSS'
    })
  },
  created() {
    if (this.footerCSS) appendStyleInHead(this.footerCSS)
  },
  mounted() {
    const footer = this.$refs.footer
    const linkList = footer.querySelectorAll('a')
    for (let i = 0; i < linkList.length; i++) {
      linkList[i].target = '_blank'
      linkList[i].setAttribute('rel', 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>
#tripla-footer-container {
  flex-shrink: 0;
}
</style>
