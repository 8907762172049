const state = {
  segmentIds: null,
  iconMessageId: null
}

const mutations = {
  setSegmentIds(state, id) {
    state.segmentIds = id
  },
  setIconMessageId(state, id) {
    state.iconMessageId = id
  }
}

const getters = {}

const actions = {
  setSegmentIds({ commit }, ids) {
    commit('setSegmentIds', ids)
  },
  setIconMessageId({ commit }, id) {
    commit('setIconMessageId', id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
