export const QUESTION_TYPE = {
  PEOPLE_PER_ROOM: 'number_of_men_women_per_room',
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
  FREE: 'free',
  FILE_ATTACHMENT: 'file_attachment'
}

export const NOTIFICATION = 'notification'

export const ANSWER_TYPE = {
  TEXT: 'text',
  OPTIONS: 'options',
  FILE: 'file'
}
