export const currencySymbolMap = {
  KRW: '₩',
  JPY: '¥',
  TWD: 'NT$'
}

export const SUPPORT_MCP_CURRENCIES = {
  JPY: { symbol: '¥', decimalDigits: 0 },
  ARS: { symbol: 'ARS', decimalDigits: 2 },
  AUD: { symbol: 'AUD', decimalDigits: 2, isSuggested: true },
  BRL: { symbol: 'BRL', decimalDigits: 2 },
  CAD: { symbol: 'CAD', decimalDigits: 2 },
  CNY: { symbol: 'CNY', decimalDigits: 2, isSuggested: true },
  CZK: { symbol: 'CZK', decimalDigits: 2 },
  DKK: { symbol: 'DKK', decimalDigits: 2 },
  EUR: { symbol: '€', decimalDigits: 2, isSuggested: true },
  HKD: { symbol: 'HKD', decimalDigits: 2 },
  INR: { symbol: 'INR', decimalDigits: 2 },
  IDR: { symbol: 'IDR', decimalDigits: 0 },
  ILS: { symbol: 'ILS', decimalDigits: 2 },
  MOP: { symbol: 'MOP', decimalDigits: 2 },
  MYR: { symbol: 'MYR', decimalDigits: 2 },
  MXN: { symbol: 'MXN', decimalDigits: 2 },
  TWD: { symbol: 'NT$', decimalDigits: 0, isSuggested: true },
  NZD: { symbol: 'NZD', decimalDigits: 2 },
  NOK: { symbol: 'NOK', decimalDigits: 2 },
  PHP: { symbol: 'PHP', decimalDigits: 2 },
  GBP: { symbol: '£', decimalDigits: 2 },
  QAR: { symbol: 'QAR', decimalDigits: 2 },
  RUB: { symbol: 'RUB', decimalDigits: 2 },
  SAR: { symbol: 'SAR', decimalDigits: 2 },
  SGD: { symbol: 'SGD', decimalDigits: 2 },
  ZAR: { symbol: 'ZAR', decimalDigits: 2 },
  KRW: { symbol: '₩', decimalDigits: 0, isSuggested: true },
  LKR: { symbol: 'LKR', decimalDigits: 2 },
  SEK: { symbol: 'SEK', decimalDigits: 2 },
  CHF: { symbol: 'CHF', decimalDigits: 2 },
  THB: { symbol: 'THB', decimalDigits: 2 },
  AED: { symbol: 'AED', decimalDigits: 2 },
  USD: { symbol: '$', decimalDigits: 2, isSuggested: true },
  VND: { symbol: 'VND', decimalDigits: 0 }
}
