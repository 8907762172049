// ez stupid encode/decode from Shangwei.tsai

import _omit from 'lodash/omit'
import { DISALLOWED_QUERIES } from 'constants/tripla'

const invertCharCode = (s = '') => {
  const code = String(s).charCodeAt(0)
  return String.fromCharCode(code > 127 ? code - 128 : code + 128)
}

export const encode = (raw = '') => {
  const inverted = String(raw || '').replace(/./g, invertCharCode)
  return encodeURIComponent(window.btoa(inverted))
}

export const decode = (raw = '') => {
  const inverted = window.atob(decodeURIComponent(String(raw || '')))
  return inverted.replace(/./g, invertCharCode)
}

export const encodeQuery = (raw = {}) => {
  const encodedQuery = {}
  DISALLOWED_QUERIES.forEach((field) => {
    if (raw.hasOwnProperty(field)) {
      encodedQuery[encode(field)] = encode(raw[field])
    }
  })
  return { ...encodedQuery, ..._omit(raw, DISALLOWED_QUERIES) }
}

const ENCODED_QUERIES_NAME = DISALLOWED_QUERIES.map(encode)
export const decodeQuery = (raw = {}) => {
  const decodedQuery = {}
  ENCODED_QUERIES_NAME.forEach((field) => {
    if (raw.hasOwnProperty(field)) {
      decodedQuery[decode(field)] = decode(raw[field])
    }
  })
  return { ...decodedQuery, ..._omit(raw, ENCODED_QUERIES_NAME) }
}

export default {
  encode,
  decode,
  encodeQuery,
  decodeQuery
}
