import router from 'routes'
import { CROSS_DOMAIN_TRACKING_KEY, DISALLOWED_QUERIES } from 'constants/tripla'

export const replaceQuery = ({ exclude = [], overrides = {} }) => {
  const query = { ...router.currentRoute.query, ...overrides }
  const allExcluedQuery = [...exclude, ...DISALLOWED_QUERIES]

  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key]) => !allExcluedQuery.includes(key))
  )

  router.replace({ query: filteredQuery }).catch(ignoreNavigationDuplicated)
}

export const clearQuery = () => {
  router.replace({ query: {} }).catch(ignoreNavigationDuplicated)
}

export const redirectToExternalLink = (url, options = {}) => {
  const { innerText = '', isOpenInNewTab = false } = options
  const urlObj = new URL(url)

  if (!urlObj.searchParams[CROSS_DOMAIN_TRACKING_KEY]) {
    urlObj.searchParams.set(CROSS_DOMAIN_TRACKING_KEY, getLinkerParameter(url))
  }

  const a = document.createElement('a')
  a.href = urlObj.toString()
  a.style.display = 'none'
  a.innerText = innerText
  isOpenInNewTab && (a.target = '_blank')

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function getLinkerParameter(url) {
  const formNode = document.createElement('FORM')

  formNode.action = url
  formNode.style.opacity = '0'

  formNode.addEventListener('submit', (event) => {
    event.preventDefault()
  })

  const buttonNode = document.createElement('BUTTON')

  buttonNode.type = 'submit'
  formNode.append(buttonNode)
  document.body.append(formNode)

  buttonNode.click()

  const _glNode = formNode.querySelector('input[name="_gl"]')
  const value = _glNode ? _glNode.value : ''

  formNode.remove()

  return value
}

function ignoreNavigationDuplicated(err) {
  if (err.name !== 'NavigationDuplicated') {
    throw err
  }
}
