import Cookies from 'js-cookie'
import store from 'store'

function getHotelDomain() {
  const domainList = window.location.host.split('.')
  if (domainList.length > 2) {
    domainList.shift()
    return domainList.join('.')
  }
  return null
}

export function setTriplaUID(triplaUID) {
  if (triplaUID && triplaUID !== Cookies.get('client_tripla_uid')) {
    const { parentUrl } = store.getters['setting/getSetting']
    let domain = ''
    if (TARGET_ENV === 'production' && parentUrl) {
      const parentLocation = new URL(parentUrl)
      domain = parentLocation.host
    } else if (['staging', 'qa'].includes(TARGET_ENV)) {
      domain = getHotelDomain()
    }
    if (domain) {
      Cookies.set('client_tripla_uid', triplaUID, { domain })
    }
  }
}

export function getTriplaUID() {
  return store.getters['setting/isFirstPartyDomainEnable']
    ? Cookies.get('client_tripla_uid') ||
        store.getters['booking/getTrackingInfoFromBw']?.triplaUID ||
        store.getters['booking/getTrackingInfoFromBw']?.user?.tripla_uid
    : null
}
