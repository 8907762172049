import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import { Korean } from 'flatpickr/dist/l10n/ko.js'
import { Mandarin } from 'flatpickr/dist/l10n/zh.js'
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js'

export const calendarTranslation = (locale) => {
  const mapping = {
    ja: Japanese,
    ko: Korean,
    zh_Hans: Mandarin,
    zh_Hant: MandarinTraditional
  }
  return mapping[locale] || null
}

export const localeMapping = {
  ja: 'ja',
  'en-US': 'en',
  'zh-hans': 'zh_Hans',
  'zh-hant': 'zh_Hant',
  ko: 'ko'
}

export const localeText = {
  ja: '日本語',
  en: 'English',
  zh_Hans: '簡体中文',
  zh_Hant: '繁體中文',
  ko: '한국어',
  id: 'Indonesian',
  ar: 'العربية',
  th: 'ไทย'
}

export const localeShortText = {
  ja: 'JP',
  en: 'EN',
  zh_Hans: 'SC',
  zh_Hant: 'TC',
  ko: 'KO'
}

function _navigatorLanguage() {
  const language =
    navigator.language ||
    navigator.browserLanguage ||
    navigator.userLanguage ||
    navigator.systemLanguage
  return language
}

function _guessBrowserLanguage(lang) {
  const lowerCaseLang = lang.toLowerCase()
  const navLangPatterns = {
    ko: /^(ko(-.*)?)$/,
    ja: /^(ja(-.*)?)$/,
    'zh-hans': /^(zh(-(hans|cn|sg).*)?)$/,
    'zh-hant': /^(zh-(hant|tw|mo|hk).*)$/
  }

  for (const key in navLangPatterns) {
    if (navLangPatterns[key].test(lowerCaseLang)) {
      return key
    }
  }

  return lang
}

function getDefaultBrowserLanguage() {
  const browserLanguage = _navigatorLanguage()
  const guessedBrowserLanguage = browserLanguage ? _guessBrowserLanguage(browserLanguage) : null
  const mappedBrowserLanguage =
    localeMapping && localeMapping.hasOwnProperty(guessedBrowserLanguage)
      ? localeMapping[guessedBrowserLanguage]
      : 'en'
  return mappedBrowserLanguage
}

export function getLanguageFromCode(code) {
  switch (code) {
    case 'en':
      return 'English'
    case 'ja':
      return 'Japanese'
    case 'ko':
      return 'Korean'
    case 'zh_Hant':
      return 'Chinese(Traditional)'
    case 'zh_Hans':
      return 'Chinese(Simplified)'
  }
}

export default getDefaultBrowserLanguage
