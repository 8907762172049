import { mapGetters, mapActions } from 'vuex'
import { sendDataToParentWindow } from 'utilities/domOperation'
import { setUserToken } from 'utilities/membership'
import _get from 'lodash/get'
import Membership from 'api/Membership'

export default {
  data() {
    return {
      isExchangingCBToken: false
    }
  },

  computed: {
    ...mapGetters({
      hotelCode: 'setting/getHotelCode',
      chatbotAutoMessageAuthId: 'setting/getChatbotAutoMessageAuthId',
      isFacilitySignInEnabled: 'setting/isFacilitySignInEnabled',
      chatbotUserId: 'setting/getChatbotUserId',
      isInCBIframe: 'setting/isInChatbotIframe',
      isSupportCompanion: 'setting/isSupportCompanion',
      adultCompanion: 'membership/getAdultCompanion',
      kidCompanion: 'membership/getKidCompanion'
    })
  },

  methods: {
    ...mapActions({
      setLoggedInUser: 'membership/setLoggedInUser'
    }),

    getTriplbotUserId() {
      return new Promise((resolve) => {
        let intervalId
        const timeoutId = setTimeout(() => {
          clearInterval(intervalId)
          resolve()
        }, 2000)

        if (this.isInCBIframe) {
          clearTimeout(timeoutId)
          resolve(this.chatbotUserId)
        } else {
          const messageHandler = (event) => {
            if (event.data.action === 'getTriplabotUserId' && event.data.id) {
              clearTimeout(timeoutId)
              clearInterval(intervalId)
              window.removeEventListener('message', messageHandler)
              resolve(event.data.id)
            }
          }

          window.addEventListener('message', messageHandler)

          intervalId = setInterval(() => {
            // maybe CB hasn't loaded when we send this message then retry in 200ms
            sendDataToParentWindow({ action: 'getTriplabotUserId' })
          }, 200)
        }
      })
    },

    sendTriplabotSession() {
      if (!this.isFacilitySignInEnabled) return

      this.isExchangingCBToken = true
      this.getTriplbotUserId().then((userId) => {
        if (!userId) {
          this.isExchangingCBToken = false
          return
        }

        Membership.getChatbotSession(this.hotelCode, {
          auth_auto_message_id: this.chatbotAutoMessageAuthId,
          user_id: userId
        })
          .then((res) => {
            if (!res) return

            sendDataToParentWindow({
              action: 'receiveTriplabotSession',
              session: res.data.session,
              isInCBIframe: this.isInCBIframe
            })
          })
          .catch((e) => {
            console.warn('[BW] failed to sendTriplabotSession or getChatbotSession', e)
            sendDataToParentWindow({ action: 'close' })
          })
          .finally(() => {
            this.isExchangingCBToken = false
          })
      })
    },

    setLoggedInSession(response) {
      setUserToken(_get(response, 'session'))
    },

    setupLocalMembershipLogin(response) {
      this.setLoggedInSession(response)
      this.setLoggedInUser(response)

      sendDataToParentWindow({
        action: 'login',
        name: this.userName
      })

      this.sendTriplabotSession()

      return Promise.all([
        Membership.getSignedInProgram(response.program_id),
        Membership.getProgramByIds(response.non_signed_in_program_ids)
      ]).then(([res, nonSignedInPrograms]) => {
        this.$store.dispatch('membership/setCompanionInfo', res.data.additional_tiers_attributes)
        this.$store.dispatch('membership/setSignedInProgram', res.data)
        this.$store.dispatch('membership/setNonSignedInPrograms', nonSignedInPrograms)
        if (
          response.access_token &&
          this.isSupportCompanion &&
          (this.adultCompanion.is_active || this.kidCompanion.is_active)
        ) {
          const companionTranslations = ['ja', 'en', 'ko', 'zh_Hans', 'zh_Hant'].map((locale) => {
            return {
              locale,
              adults: this.adultCompanion.translationMap?.[locale]?.name,
              children: this.kidCompanion.translationMap?.[locale]?.name
            }
          })
          sendDataToParentWindow({
            action: 'receiveCompanionInfo',
            payload: {
              adultsCompanionEnable: this.adultCompanion.is_active,
              childrenCompanionEnable: this.kidCompanion.is_active,
              companionTranslations
            }
          })
        }
      })
    }
  }
}
