import Cookies from 'js-cookie'
import store from 'store'
import { parseParamsURL } from 'utilities/paramsFormat'

export const searchCriteriaList = [
  'checkin',
  'checkout',
  'type',
  'is_day_use',
  'rooms',
  'parentUrl',
  'adults',
  'kids_tiers',
  'room_count',
  'search_type',
  'nights'
]

export function setSearchCriteria() {
  const urlParams = parseParamsURL(window.location.search)
  let searchParams = ''
  Object.keys(urlParams).forEach((key) => {
    if (searchCriteriaList.includes(key)) {
      searchParams = `${searchParams}&${key}=${urlParams[key]}`
    }
  })
  const in1Hour = 1 / 24
  Cookies.set(`${store.getters['setting/getHotelCode']}_search`, btoa(searchParams), {
    expires: in1Hour
  })
}

export function getSearchCriteria(hotelCode) {
  const value = Cookies.get(`${hotelCode}_search`)
  return value ? atob(value) : null
}

export function removeSearchCriteria() {
  Cookies.remove(`${store.getters['setting/getHotelCode']}_search`)
}
