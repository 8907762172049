import Vue from 'vue'
import store from 'store'

Vue.directive('theme-btn', function (el, { value = true }) {
  if (!value) return

  el.style.background = value ? store.getters['setting/getColor'] : ''
  el.style.color = value ? store.getters['setting/getFontColor'] : ''
  el.style.border = value ? `1px solid ${el.style.background}` : ''
  el.classList.add('btn-theme')
})

Vue.directive('theme-switch', function (el, { value = true }) {
  el.classList.add('btn-switch-theme')
})

Vue.directive('theme-btn-outline', function (el, { value = true }) {
  if (!value) return

  el.style.background = value ? '#fff' : ''
  el.style.color = value ? store.getters['setting/getColor'] : ''
  el.style.border = value ? `1px solid ${store.getters['setting/getColor']}` : ''
  el.classList.add('btn-theme')
})

Vue.directive('theme-font', function (el, { value = true }) {
  el.style.color = value ? store.getters['setting/getColor'] : ''
})
