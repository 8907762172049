export function selectAdultChildCount(selectedItem) {
  const adultsCount =
    selectedItem?.matched_search_criteria?.adults_with_companion ||
    selectedItem?.matched_search_criteria?.adults_count ||
    0
  const childrenCount =
    selectedItem?.matched_search_criteria?.children_with_kid_tier_A_companion ||
    selectedItem?.matched_search_criteria?.children_count ||
    0
  return { adultsCount, childrenCount }
}
