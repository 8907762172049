import Vue from 'vue'
import { logger } from './logger'
const Rollbar = require('vue-rollbar')
const isIE = () => {
  const ua = navigator.userAgent
  return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1
  // https://attacomsian.com/blog/javascript-dom-detect-internet-explorer-browser
}

Vue.use(Rollbar, {
  accessToken: process.env.ROLLBAR_TOKEN,
  captureUncaught: false,
  captureUnhandledRejections: true,
  enabled:
    (process.env.NODE_ENV === 'production' || TARGET_ENV === 'staging' || TARGET_ENV === 'qa') &&
    !isIE(),
  environment: TARGET_ENV,
  captureIp: true,
  payload: {
    client: {
      javascript: {
        code_version: COMMIT_HASH,
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  }
})

if (process.env.NODE_ENV === 'production') {
  Vue.config.errorHandler = (err, _vm, _info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    logger.error(err)
  }

  // way too noisy

  // window.onerror = function (message, source, lineno, colno, error) {
  //   if (error) {
  //     logger.errorFromString(
  //       `Source: ${source}, line:${lineno}, column:${colno}, Message: ${message}`
  //     )
  //     logger.error(error)
  //   } else {
  //     logger.errorFromString(
  //       `Error: ${error}. Message: ${message}. Source: ${source}, line:${lineno}, column:${colno}`
  //     )
  //   }
  // }
}

Vue.prototype.$logger = logger
