import { fetchData } from './customFetch'
const getData = (url, options = {}) => fetchData({ ...options, url })
const postData = (url, data, type) => fetchData({ url, method: 'POST', data, type })
const putData = (url, data, type) => fetchData({ url, method: 'PUT', data, type })
const patchData = (url, data, type) => fetchData({ url, method: 'PATCH', data, type })
const deleteData = (url, data) => fetchData({ url, method: 'DELETE', data })

export default {
  getData,
  postData,
  putData,
  patchData,
  deleteData
}
