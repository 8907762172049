import Vue from 'vue'
import Loading from 'components/Common/Loading.vue'
const Spinner = Vue.extend(Loading)

Vue.directive('loading', {
  bind(el, binding) {
    const shouldDisplay = binding && binding.value

    const spinner = new Spinner({
      el: document.createElement('div'),
      propsData: {
        modifiers: binding.modifiers || {}
      }
    })

    el.spinner = spinner
    el.appendChild(spinner.$el)

    if (shouldDisplay) {
      Vue.nextTick(() => {
        el.spinner.localVisible = true
      })
    }
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      el.spinner.localVisible = binding.value
    }
  },
  unbind(el, binding) {
    el.spinner && el.spinner.$destroy()
  }
})
