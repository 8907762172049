import API from './fetch'
import {
  hotelListUrl,
  searchHotelsUrl,
  hotelInfoUrl,
  searchRatingsHotelsUrl,
  hotelInventoryUrl,
  getRecommenderWidget
} from './url'
import { appendParams } from 'utilities/paramsFormat'
import _flatMap from 'lodash/flatMap'
import _range from 'lodash/range'

const Hotels = {
  getSearchHotels(params) {
    return API.getData(appendParams(searchHotelsUrl(), params))
  },
  getRatingsHotels(params) {
    return API.getData(appendParams(searchRatingsHotelsUrl(), params))
  },
  async getFacilities(params = {}) {
    const url = appendParams(hotelListUrl(), params)
    const res = await API.getData(url)

    // fetch all pages
    if (res?.page === 1 && res?.total_pages > 1 && res?.data?.length) {
      const results = await Promise.all(
        _range(2, res.total_pages + 1).map((page) =>
          API.getData(appendParams(hotelListUrl(), { ...params, page }))
        )
      )
      return {
        ...res,
        data: [...res.data, ..._flatMap(results, 'data')],
        total_pages: 1
      }
    }

    // fetch specific page
    return res
  },
  getInfo(id) {
    return API.getData(hotelInfoUrl(id))
  },
  getInventoryCalendar(brandId, params) {
    return API.getData(appendParams(hotelInventoryUrl(brandId), params))
  },
  getRecommenderWidgetSetting(code) {
    return API.getData(getRecommenderWidget(code))
  }
}

export default Hotels
