export const GMO_MAINTENANCE_PERIODS = [
  // qa test query
  ...(process.env.TARGET_ENV === 'qa' && /qa_gmo_maintenance_after=([^&]+)/.exec(location.search)
    ? [
        {
          startTime: decodeURIComponent(
            /qa_gmo_maintenance_after=([^&]+)/.exec(location.search)?.[1]
          )?.replaceAll(/\s/g, '+'),
          endTime: '2099-12-31T23:59:59+09:00'
        }
      ]
    : []),
  // production configuration start
  {
    startTime: '2024-11-18T04:00:00+09:00',
    endTime: '2024-11-18T06:00:00+09:00'
  }
  // production configuration end
]

export function checkIsUnderMaintenance({ periods, currentTime = new Date() }) {
  if (window.Cypress) return false

  return periods.some(
    (period) => currentTime >= new Date(period.startTime) && currentTime <= new Date(period.endTime)
  )
}

export function checkIfUnderGMOMaintenance() {
  return checkIsUnderMaintenance({ periods: GMO_MAINTENANCE_PERIODS })
}

export function getMsToNextGMOMaintenance() {
  const nextMaintenances = GMO_MAINTENANCE_PERIODS.map(
    (period) => new Date(period.startTime) - new Date()
  ).filter((msToStart) => msToStart > 0)
  return nextMaintenances.length ? Math.min(...nextMaintenances) : null
}
