<template>
  <b-pagination v-bind="$attrs" v-on="$listeners">
    <template #prev-text><arrow-left class="pagination-icon" alt="Prev" /></template>
    <template #next-text><arrow-right class="pagination-icon" alt="Next" /></template>
  </b-pagination>
</template>

<script>
import ArrowLeft from 'assets/keyboard_arrow_left_gray.svg?inline'
import ArrowRight from 'assets/keyboard_arrow_right_gray.svg?inline'

export default {
  name: 'TPagination',
  components: { ArrowLeft, ArrowRight }
}
</script>

<style lang="scss" scoped>
.pagination-icon {
  margin: -5px;
}
</style>
