import API from './fetch'
import {
  cancelLotteryResultURL,
  createLotteryResultURL,
  getLotteryResultURL,
  lotteryEventSettingURL,
  getMyPageLotteryResultURL
} from 'api/url'
import { appendParams } from 'utilities/paramsFormat'

export const RoomLottery = {
  getLotteryResult(params) {
    return API.getData(getLotteryResultURL(params)).then((res) => {
      // remove FAKE_NO from BE coz it causes wrong null checks
      if (res.data.reservation?.reservation_number === 'FAKE_NO') {
        res.data.reservation.reservation_number = null
      }

      // set entry number here for later checks in resrevation object
      if (!res.data.reservation?.lottery_result?.entry_number) {
        res.data.reservation.lottery_result = {
          entry_number: res.data.entry_number,
          status: res.data.status
        }
      }

      return res
    })
  },

  createLotteryResult(hotelId, payload) {
    return API.postData(createLotteryResultURL({ hotelId }), payload)
  },

  updateLotteryResult(params, payload) {
    return API.putData(getLotteryResultURL(params), payload)
  },

  cancelLotteryResult(params) {
    return API.putData(cancelLotteryResultURL(params))
  },

  getLotteryEventSetting(lotteryEventUuid) {
    return API.getData(lotteryEventSettingURL(lotteryEventUuid))
  },

  getMyPageLotteryResults(params) {
    return API.getData(appendParams(getMyPageLotteryResultURL(), params))
  },

  deleteIncompleteReservation(params) {
    return API.postData(`${getLotteryResultURL(params)}/delete_in_complete_reservation`)
  }
}
