import API from './fetch'
import { searchRoomURL, getRoomsInfoURL, recommendationRoomURL, roomPriceUrl } from './url'
import { appendParams } from 'utilities/paramsFormat'
import _pickBy from 'lodash/pickBy'
import _identity from 'lodash/identity'

const Rooms = {
  get(params) {
    const url = appendParams(searchRoomURL(), params)
    return API.getData(url)
  },
  getSelectedData(payload) {
    const url = appendParams(getRoomsInfoURL(), {
      ...payload,
      coupon_code: payload.coupon_code || payload.voucher_coupon_code,
      include_extra_out_of_stock: true
    })
    return API.getData(url)
  },
  getRecommendation(payload) {
    const url = appendParams(recommendationRoomURL(), payload)
    return API.getData(url)
  },
  getPrice(id, payload) {
    const url = appendParams(roomPriceUrl(id), payload)
    return API.getData(url)
  }
}

export default Rooms
