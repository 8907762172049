import store from 'store'
import paths from 'routes/paths'
import { sendDataToParentWindow } from 'utilities/domOperation'

export const asyncComponentLoader = (folderName, fileName = 'index') => () =>
  import(/* webpackChunkName: "page-[request]" */ `../views/${folderName}/${fileName}.vue`)

export function appendCodeToRoute(to, next) {
  if (!to.query.code) {
    to.query.code = store.getters['setting/getSetting'].code
    next({ query: to.query, params: to.params })
  } else {
    next()
  }
}

export function hideChatbotSignIn(to) {
  if (!store.getters['setting/isChatbotEnabledOnBW'] || store.getters['setting/isInChatbotIframe'])
    return

  const routeNames = [
    paths.reservation.guestDetail,
    paths.reservation.confirmation,
    paths.reservation.review
  ]
  const value = routeNames.includes(to.name)
  sendDataToParentWindow({
    action: 'hide-chatbot-sign-in',
    value
  })
}

export function waitInitAPIArrived() {
  if (store.getters['search/isInitAPIArrived'] === true) {
    return Promise.resolve()
  }

  return new Promise((resolve) => {
    const unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === 'search/setInitAPIArrived' && mutation.payload === true) {
        unsubscribe()
        resolve()
      }
    })
  })
}
