import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from 'locales'
import getDefaultBrowserLanguage from './locales/utilities'
import triplaLocalStorage from 'utilities/triplaLocalStorage'
import { createI18n } from 'vue-i18n-bridge'
import { isOta } from 'constants/tripla'

Vue.use(VueI18n, { bridge: true })

export default createI18n(
  {
    legacy: false,
    locale: triplaLocalStorage.getItem('triplabot_locale') || getDefaultBrowserLanguage(), // set locale
    fallbackLocale: isOta ? 'ja' : 'en',
    messages: locales
  },
  VueI18n
)
