<template>
  <div class="ota-app">
    <router-view :key="reloadKey" @changeLocale="changeLocale" />
  </div>
</template>

<script>
import changeLocaleMixin from 'mixins/changeLocaleMixin'
import triplaLocalStorage from 'utilities/triplaLocalStorage'
import _isEmpty from 'lodash/isEmpty'
import PATH from 'routes/paths'

export default {
  name: 'OtaApp',
  mixins: [changeLocaleMixin],
  metaInfo() {
    const defaultMetaInfo = {
      meta: [
        {
          name: 'robots',
          content: this.$route.name === PATH.ota.home ? 'index, follow' : 'noindex, nofollow'
        },
        {
          name: 'msapplication-TileColor',
          content: '#da532c'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        }
      ],
      link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#c21e58' }
      ]
    }

    if (this.$route.meta.pageTitle) {
      const hotelName = this.$store.getters['setting/getHotelName']
      defaultMetaInfo.title =
        'tripla | ' +
        (this.$route.meta.noHotelCode ? '' : `${hotelName || '~'} | `) +
        this.$t(`meta.${this.$route.meta.pageTitle}`, this.$i18n.locale)
    }

    if (this.$route.name === PATH.ota.home) {
      defaultMetaInfo.meta.push({
        name: 'description',
        content: this.$t('meta.otaHomeDescription')
      })
    }

    return defaultMetaInfo
  },
  provide() {
    return { changeLocale: this.changeLocale }
  },
  created() {
    this.checkLocale()
  },
  mounted() {
    document.documentElement.style.setProperty('--theme-btn-color', '#C21E58')
    document.documentElement.style.setProperty('--theme-font-color', '#fff')
  },
  methods: {
    checkLocale() {
      const currentLang = triplaLocalStorage.getItem('triplabot_locale')

      if (currentLang) return

      const localeFromQuery = this.$route.query.locale
      if (localeFromQuery) {
        this.changeLocale(localeFromQuery)
      }
    }
  }
}
</script>
