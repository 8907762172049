const prefixDataLayer = {
  search_result: {
    't-utm_medium': '$utm_medium',
    't-utm_source': '$utm_source',
    't-utm_campaign': '$utm_campaign',
    't-campaignid': '$campaignid',
    't-adgroupid': '$adgroupid',
    't-keyword': '$keyword',
    't-gclid': '$gclid',
    't-yclid': '$yclid',
    't-brand_name': '$brand_name',
    't-checkin_date': '$checkin_date',
    't-checkout_date': '$checkout_date',
    't-cookie_id': '$cookie_id',
    't-day_use': '$day_use',
    't-hotel_id': '$hotel_id',
    't-hotel_name': '$hotel_name',
    't-hotel_prefecture': '$hotel_prefecture',
    't-language': '$language',
    't-number_of_adults': '$number_of_adults',
    't-number_of_children': '$number_of_children',
    't-number_of_rooms': '$number_of_rooms',
    't-page_category': '$page_category',
    't-search_type': '$search_type',
    't-sent_at': '$sent_at',
    't-user_id': '$user_id',
    't-search_id': '$search_id',
    't-event_type': 'tripla',
    't-room_search_filters': '$room_search_filters',
    _trigger: 'onload'
  },
  guest_detail: {
    't-utm_medium': '$utm_medium',
    't-utm_source': '$utm_source',
    't-utm_campaign': '$utm_campaign',
    't-campaignid': '$campaignid',
    't-adgroupid': '$adgroupid',
    't-keyword': '$keyword',
    't-gclid': '$gclid',
    't-yclid': '$yclid',
    't-brand_name': '$brand_name',
    't-cookie_id': '$cookie_id',
    't-language': '$language',
    't-page_category': '$page_category',
    't-sent_at': '$sent_at',
    't-user_id': '$user_id',
    't-search_id': '$search_id',
    't-event_type': 'tripla',
    't-rooms': [
      {
        page_category: '$page_category',
        brr: '$brr',
        number_of_adults_per_room: '$number_of_adults_per_room',
        number_of_children_per_room: '$number_of_children_per_room',
        plan_id: '$plan_id',
        room_id: '$room_id',
        search_id: '$search_id',
        type: 'room_booking_detail'
      }
    ],
    _trigger: 'onload'
  },
  payment_detail: {
    't-utm_medium': '$utm_medium',
    't-utm_source': '$utm_source',
    't-utm_campaign': '$utm_campaign',
    't-campaignid': '$campaignid',
    't-adgroupid': '$adgroupid',
    't-keyword': '$keyword',
    't-gclid': '$gclid',
    't-yclid': '$yclid',
    't-brand_name': '$brand_name',
    't-checkin_date': '$checkin_date',
    't-checkout_date': '$checkout_date',
    't-cookie_id': '$cookie_id',
    't-coupon_id': '$coupon_id',
    't-currency_code': '$currency_code',
    't-hotel_id': '$hotel_id',
    't-hotel_name': '$hotel_name',
    't-hotel_prefecture': '$hotel_prefecture',
    't-language': '$language',
    't-number_of_adults': '$number_of_adults',
    't-number_of_children': '$number_of_children',
    't-number_of_days_to_checkin': '$number_of_days_to_checkin',
    't-number_of_nights': '$number_of_nights',
    't-number_of_rooms': '$number_of_rooms',
    't-page_category': '$page_category',
    't-sent_at': '$sent_at',
    't-transaction_price_without_tax': '$transaction_price_without_tax',
    't-transaction_tax_amount': '$transaction_tax_amount',
    't-transaction_total_price': '$transaction_total_price',
    't-user_id': '$user_id',
    't-search_id': '$search_id',
    't-event_type': 'tripla',
    't-rooms': [
      {
        page_category: '$page_category',
        brr: '$brr',
        number_of_adults_per_room: '$number_of_adults_per_room',
        number_of_children_per_room: '$number_of_children_per_room',
        plan_id: '$plan_id',
        room_id: '$room_id',
        search_id: '$search_id',
        type: 'room_booking_detail'
      }
    ],
    _trigger: 'onload'
  },
  confirmation: {
    't-utm_medium': '$utm_medium',
    't-utm_source': '$utm_source',
    't-utm_campaign': '$utm_campaign',
    't-campaignid': '$campaignid',
    't-adgroupid': '$adgroupid',
    't-keyword': '$keyword',
    't-gclid': '$gclid',
    't-yclid': '$yclid',
    't-booking_date': '$booking_date',
    't-booking_route': '$booking_route',
    't-brand_name': '$brand_name',
    't-checkin_date': '$checkin_date',
    't-checkout_date': '$checkout_date',
    't-cookie_id': '$cookie_id',
    't-coupon_code': '$coupon_code',
    't-coupon_id': '$coupon_id',
    't-currency_code': '$currency_code',
    't-hotel_id': '$hotel_id',
    't-hotel_name': '$hotel_name',
    't-hotel_prefecture': '$hotel_prefecture',
    't-language': '$language',
    't-number_of_adults': '$number_of_adults',
    't-number_of_children': '$number_of_children',
    't-number_of_days_to_checkin': '$number_of_days_to_checkin',
    't-number_of_nights': '$number_of_nights',
    't-number_of_rooms': '$number_of_rooms',
    't-page_category': '$page_category',
    't-sent_at': '$sent_at',
    't-transaction_id': '$transaction_id',
    't-transaction_method': '$transaction_method',
    't-transaction_price_without_tax': '$transaction_price_without_tax',
    't-transaction_tax_amount': '$transaction_tax_amount',
    't-transaction_total_price': '$transaction_total_price',
    't-user_id': '$user_id',
    't-search_id': '$search_id',
    't-event_type': 'tripla',
    _trigger: 'onload'
  },
  reservation_cancel: {
    't-booking_cancellation_reason': '$booking_cancellation_reason',
    't-brand_name': '$brand_name',
    't-checkin_date': '$checkin_date',
    't-checkout_date': '$checkout_date',
    't-cookie_id': '$cookie_id',
    't-coupon_code': '$coupon_code',
    't-coupon_id': '$coupon_id',
    't-hotel_id': '$hotel_id',
    't-hotel_name': '$hotel_name',
    't-hotel_prefecture': '$hotel_prefecture',
    't-language': '$language',
    't-number_of_adults': '$number_of_adults',
    't-number_of_children': '$number_of_children',
    't-number_of_days_to_checkin': '$number_of_days_to_checkin',
    't-number_of_nights': '$number_of_nights',
    't-number_of_rooms': '$number_of_rooms',
    't-page_category': '$page_category',
    't-sent_at': '$sent_at',
    't-transaction_id': '$transaction_id',
    't-transaction_method': '$transaction_method',
    't-transaction_price_without_tax': '$transaction_price_without_tax',
    't-transaction_refund_amount': '$transaction_refund_amount',
    't-transaction_tax_amount': '$transaction_tax_amount',
    't-transaction_total_price': '$transaction_total_price',
    't-user_id': '$user_id',
    't-event_type': 'tripla',
    't-rooms': [
      {
        page_category: '$page_category',
        brr: '$brr',
        number_of_adults_per_room: '$number_of_adults_per_room',
        number_of_children_per_room: '$number_of_children_per_room',
        plan_id: '$plan_id',
        room_id: '$room_id',
        search_id: '$search_id',
        type: 'room_booking_detail'
      }
    ],
    _trigger: 'onclick'
  },
  reservation_update: {
    't-brand_name': '$brand_name',
    't-cookie_id': '$cookie_id',
    't-hotel_id': '$hotel_id',
    't-hotel_name': '$hotel_name',
    't-hotel_prefecture': '$hotel_prefecture',
    't-language': '$language',
    't-new_checkin_date': '$new_checkin_date',
    't-new_checkout_date': '$new_checkout_date',
    't-new_number_of_adults': '$new_number_of_adults',
    't-new_number_of_children': '$new_number_of_children',
    't-new_number_of_rooms': '$new_number_of_rooms',
    't-page_category': '$page_category',
    't-sent_at': '$sent_at',
    't-transaction_id': '$transaction_id',
    't-user_id': '$user_id',
    't-event_type': 'tripla',
    _trigger: 'onclick'
  }
}

export function addPrefixDataLayer(currentDataLayer) {
  if (!currentDataLayer.search_result) {
    const formattedDataLayer = {}
    Object.keys(prefixDataLayer).forEach((page) => {
      formattedDataLayer[page] = [
        {
          object: prefixDataLayer[page],
          page
        }
      ]
    })
    return formattedDataLayer
  } else {
    const formattedDataLayer = JSON.parse(JSON.stringify(currentDataLayer))
    Object.keys(currentDataLayer).forEach((page) => {
      formattedDataLayer[page][0].object = {
        ...prefixDataLayer[page],
        ...currentDataLayer[page][0].object
      }
    })
    return formattedDataLayer
  }
}
