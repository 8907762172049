function detectLocalStorageAvailability() {
  let isAvailable = true
  try {
    localStorage.getItem('test')
  } catch {
    isAvailable = false
  }
  return isAvailable
}

export default {
  isAvailable: detectLocalStorageAvailability(),
  setItem(key, payload) {
    if (this.isAvailable) {
      localStorage.setItem(key, payload)
    }
  },
  getItem(key) {
    if (this.isAvailable) {
      return localStorage.getItem(key)
    }
    return null
  },
  removeItem(key) {
    console.info('[triplaLocalStorage] remove item', key)
    if (this.isAvailable) {
      localStorage.removeItem(key)
    }
  }
}
