/*
   About TriplaPayRedirectPath
   https://umami-me.atlassian.net/browse/CON-19383

   Currently the tripla payment path is like
   Payment A: bw.staging.tripla.ai/#/payments/1?code=hotel_code
   Payment B: bw.staging.tripla.ai/#/payments/2?code=hotel_code

   When me make the url into an QR code, When iPhone scan payment A and then payment B,
   iPhone will think 2 links are same and lead user to payment A even after user scan payment B
   I guess this is because # symbol in url

   so now QR code will contain
   bw.staging.tripla.ai?code=hotel_code&triplaPaymentNo=1
   and here we redirect user to
   bw.staging.tripla.ai/#/payments/1?code=hotel_code
*/
export const triplaPayRedirectPath = () => {
  const triplaPaymentNo = location.search.match(/triplaPaymentNo=([^#/&]+)/)?.[1]
  if (triplaPaymentNo) {
    history.replaceState({}, () => {}, `/payments/${triplaPaymentNo}${location.search}`)
  }
}
