import API from './fetch'
import {
  loginURL,
  signUpURL,
  updatePasswordURL,
  resetPasswordURL,
  signOutURL,
  confirmURL,
  showURL,
  updateEmailUrl,
  resendConfirmEmailUrl,
  updateUserUrl,
  deleteAccountURL,
  getProgramURL,
  resendConfirmationURL,
  facebookLoginURL,
  lineLoginURL,
  getBrandProgramURL,
  verifySignUpDetailsURL,
  getCreditCardsURL,
  deleteCreditCardURL,
  setupURL,
  getChatbotSessionURL,
  userInfo,
  userIdentity,
  newTokenURL,
  switchMembershipURL,
  openIdLoginURL,
  creansmaerdLoginURL
} from './url'
import store from 'store'
import { appendParams, setAppType, parseParamsURL } from 'utilities/paramsFormat'
import { getUserToken } from 'utilities/membership'
import Vue from 'vue'
import triplaLocalStorage from 'utilities/triplaLocalStorage'

const Membership = {
  getUserInfo() {
    return API.getData(userInfo())
  },
  getUserIdentity(params, headers = {}) {
    return API.getData(userIdentity(), { headers })
  },
  getChatbotSession(hotelCode, params = {}) {
    const token = getUserToken()
    if (!token) {
      const urlParams = parseParamsURL(window.location.search)
      const isInCBIframe = urlParams.isInCBIframe === 'true'
      Vue.rollbar.info('memberships/chatbot_session without token', {
        hotelCode,
        params,
        isInCBIframe,
        localStorageAvailable: triplaLocalStorage.isAvailable
      })

      return Promise.resolve()
    }
    return API.getData(appendParams(getChatbotSessionURL(hotelCode), params))
  },
  login(data) {
    const hotelId = store.getters['setting/getInit']?.hotel_id
    return API.postData(loginURL(hotelId), data)
  },
  brandCustomAuth(code) {
    const hotelId = store.getters['setting/getInit']?.hotel_id
    return API.postData(loginURL(hotelId), { grant_type: 'custom_authentication_code', code })
  },
  getNewToken(data, hotel_code) {
    return API.postData(newTokenURL(hotel_code), data)
  },
  facebookLogin(data) {
    setAppType(store, data)
    return API.postData(facebookLoginURL(), data)
  },
  creansmaerdLogin(data) {
    return API.postData(creansmaerdLoginURL(), data)
  },
  openIdLogin(data) {
    setAppType(store, data)
    return API.postData(openIdLoginURL(), data)
  },
  lineLogin(data) {
    setAppType(store, data)
    return API.postData(lineLoginURL(), data)
  },
  signup(data) {
    setAppType(store, data)
    return API.postData(signUpURL(), data)
  },
  updatePassword(data) {
    return API.putData(updatePasswordURL(), data)
  },
  triggerResetPassword(data) {
    return API.postData(resetPasswordURL(), data)
  },
  logout() {
    return API.deleteData(signOutURL(), null)
  },
  confirm(params) {
    return API.getData(appendParams(confirmURL(), params))
  },
  getCurrentUser() {
    return API.getData(showURL())
  },
  updateEmail(data) {
    return API.putData(updateEmailUrl(), data)
  },
  resendEmail() {
    return API.getData(resendConfirmEmailUrl())
  },
  update(data) {
    return API.putData(updateUserUrl(), data)
  },
  deleteAccount(data) {
    return API.postData(deleteAccountURL(), data)
  },
  getProgram(id) {
    return API.getData(getProgramURL(id))
  },
  getBrandProgram() {
    return API.getData(getBrandProgramURL())
  },
  getSignedInProgram(programID) {
    return API.getData(`${getBrandProgramURL()}/${programID}`)
  },
  getProgramByIds(ids = []) {
    return Promise.all(
      ids.map((id) =>
        API.getData(`${getBrandProgramURL()}/${id}`)
          .then((res) => res?.data)
          .catch(() => {})
      )
    )
  },
  getCreditCards() {
    return API.getData(getCreditCardsURL())
  },
  deleteCreditCard(id, payload) {
    return API.deleteData(deleteCreditCardURL(id), payload)
  },
  resendConfirmation(data) {
    return API.postData(resendConfirmationURL(), data)
  },
  verifySignUpDetails(data) {
    return API.postData(verifySignUpDetailsURL(), data)
  },
  setup(data) {
    return API.postData(setupURL(), data)
  },
  switchMembership(programID) {
    return API.postData(switchMembershipURL(), { program_id: programID })
  }
}

export default Membership
