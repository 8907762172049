import API from './fetch'
import { cancellationPolicyURL } from './url'
import { generateParamsURL } from 'utilities/paramsFormat'
import _find from 'lodash/find'
import _reject from 'lodash/reject'
import _sortBy from 'lodash/sortBy'
import i18n from '../i18n'

const CancellationPolicies = {
  indexApi(query = {}, hotelId) {
    return API.getData(
      generateParamsURL(cancellationPolicyURL(hotelId), query, ['room_plan_codes'])
    )
  },
  get(id, hotelId) {
    return API.getData(`${cancellationPolicyURL(hotelId)}/${id}`).then((res) => {
      const cancelDeadTime = res?.data?.cancel_before_at
      const parts = _sortBy(res?.data?.cancellation_policy_parts, 'days_before').reverse()
      const sortedPercentList = _sortBy(
        res?.data?.cancellation_policy_parts,
        'percentage_of_cost'
      ).reverse()
      const policyRuleWithTime = sortedPercentList
        .filter((policy) => policy.percentage_of_cost === sortedPercentList[0].percentage_of_cost)
        .sort((a, b) => b.days_before - a.days_before)[0]
      const daysBeforeWithTime = cancelDeadTime > 0 ? policyRuleWithTime?.days_before : null
      const cancelRateOnTheDay = _find(parts, { days_before: 0 })?.percentage_of_cost ?? 100
      const restParts = _reject(_reject(parts, { days_before: 0 }), { percentage_of_cost: 0 })
      const freeDayThreshold = (parts[0]?.days_before || 0) + 1

      return {
        ...res,
        lines: [
          i18n.t('cancellationPolicy.policyHeader'),
          parts.length === 1 && cancelDeadTime > 0
            ? i18n.t('cancellationPolicy.cancelOnTheDayFree', [`${cancelDeadTime}:00`])
            : i18n.t('cancellationPolicy.freePolicyLine', [freeDayThreshold]),
          ...restParts.map((part) =>
            daysBeforeWithTime === part.days_before
              ? i18n.t('cancellationPolicy.policyLineWithTime', [
                  part.days_before,
                  `${cancelDeadTime}:00`,
                  part.percentage_of_cost
                ])
              : i18n.t('cancellationPolicy.policyLine', [part.days_before, part.percentage_of_cost])
          ),
          daysBeforeWithTime === 0
            ? i18n.t('cancellationPolicy.cancelOnTheDayCharge', [
                `${cancelDeadTime}:00`,
                cancelRateOnTheDay
              ])
            : i18n.t('cancellationPolicy.cancelOnTheDay', [cancelRateOnTheDay]),
          i18n.t('cancellationPolicy.noShowLine', [res.data.no_show_percentage_of_cost])
        ]
      }
    })
  }
}

export default CancellationPolicies
