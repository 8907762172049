import Vue from 'vue'

const EXCLUDED_ERRORS = ['Failed to fetch', 'Network request failed']

export const logger = {
  error: (error, errorName) => {
    sendError(error, errorName)
  },
  errorFromJson: (error) => {
    try {
      error.response.json().then((errorParsed) => {
        const errorToSend = errorParsed && errorParsed.error
        this.errorFromString(errorToSend)
      })
    } catch {
      sendError(error)
    }
  },
  errorFromString: (error) => {
    const errorToSend = new Error(error)
    sendError(errorToSend)
  }
}

function sendError(error, errorName) {
  if (process.env.ROLLBAR_TOKEN) {
    if (error instanceof Error || error instanceof String) {
      const errorMessage = error.message || error
      if (!EXCLUDED_ERRORS?.some((exclude) => errorMessage?.includes?.(exclude))) {
        Vue.rollbar.error(error)
      } else {
        console.log(error)
      }
    } else {
      const name = errorName || error?.message || error?.msg
      Vue.rollbar.error(name, error)
    }
  } else {
    console.error(error)
  }
}
