<template>
  <div v-show="visible" class="modal-background"></div>
</template>

<script>
export default {
  name: 'ModalBackground',
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible(value) {
      if (value) {
        document.getElementById('tripla-app-wrapper').classList.add('modal-open')
      } else {
        document.getElementById('tripla-app-wrapper').classList.remove('modal-open')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 101;
}
</style>
