import _intersection from 'lodash/intersection'

const state = {
  extras: [],
  selectedExtras: {}
}

const convertDeliveryTimesToList = (normalized_delivery_times) => {
  const deliveryTime = []
  let quantity = 0
  let price = 0
  let priceWithTax = 0
  for (const property in normalized_delivery_times) {
    const currentQuantity = normalized_delivery_times[property]?.quantity
    quantity = quantity + currentQuantity
    price = price + currentQuantity * normalized_delivery_times[property]?.price
    priceWithTax =
      priceWithTax + currentQuantity * normalized_delivery_times[property]?.priceWithTax
    deliveryTime.push({
      delivered_at: normalized_delivery_times[property]?.delivered_at,
      quantity: currentQuantity,
      price: normalized_delivery_times[property]?.price // price without tax
    })
  }
  return { deliveryTime, quantity, price, priceWithTax }
}

const getters = {
  getExtras: (state) => {
    return state.extras.filter((extra) => {
      if (extra.extra_type === 'generic') {
        return true
      } else {
        const days = _intersection(
          extra.booking_available_dates,
          extra.prices.map((price) => price.date.replace(/-/g, '/'))
        )
        return days.length > 0
      }
    })
  },
  getSelectedExtras: (state) => {
    return state.selectedExtras
  },
  /**
   *
   *
   * @param {*} current state
   * @returns extras list in an array format
   *  extra_items: [{ extra_id: 1, quantity: 3, delivery_times: [{ delivered_at: "2020/01/01 18:00", quantity: 3 }], answer: "I love cake!!!"}]
   */
  getSelectedExtrasInArray: (state) => () => {
    try {
      const extras = []
      let totalPrice = 0
      let totalPriceWithTax = 0
      for (const property in state.selectedExtras) {
        const list = convertDeliveryTimesToList(
          state.selectedExtras[property]?.normalized_delivery_times
        )
        if (list?.quantity === 0) continue
        extras.push({
          extra_id: state.selectedExtras[property]?.extra_id,
          answer: state.selectedExtras[property]?.answer,
          delivery_times: list?.deliveryTime,
          quantity: list?.quantity
        })
        totalPrice = totalPrice + list?.price
        totalPriceWithTax = totalPriceWithTax + list?.priceWithTax
      }
      return { extras, totalPrice, totalPriceWithTax }
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = {
  setSelectedExtraItems: (state, { id, data }) => {
    state.selectedExtras[id] = data
  },
  setExtraItems: (state, data) => {
    state.extras = data
  },
  clearSelectedExtraItems: (state) => {
    state.selectedExtras = {}
  },
  overrideSelectedExtraItems: (state, data) => {
    state.selectedExtras = data
  }
}

const actions = {
  setSelectedExtraItems: ({ commit }, { id, data }) => {
    commit('setSelectedExtraItems', { id, data })
  },
  setExtraItems: ({ commit }, data) => {
    commit('setExtraItems', data)
  },
  clearSelectedExtraItems({ commit }) {
    commit('clearSelectedExtraItems')
  },
  overrideSelectedExtraItems({ commit }, data) {
    commit('overrideSelectedExtraItems', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
