import Vue from 'vue'
import ModalBackground from 'components/Common/ModalBackground.vue'
const ModalBackgroundLayout = Vue.extend(ModalBackground)

Vue.directive('modal-background', {
  bind(el, binding, vnode) {
    const shouldDisplay = binding && binding.value
    const background = new ModalBackgroundLayout({
      el: document.createElement('div')
    })

    el.background = background
    el.appendChild(background.$el)

    if (shouldDisplay) {
      Vue.nextTick(() => {
        el.background.visible = true
      })
    }

    el.background.$el.addEventListener('click', () => {
      vnode.context.closeBackground()
    })
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      el.background.visible = binding.value
    }
  },
  unbind(el, binding) {
    el.background && el.background.$destroy()
  }
})
