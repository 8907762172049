export const state = {
  // Search form holds the current value of the Search bar
  searchForm: {
    checkin: null,
    checkout: null,
    adults: 2,
    type: null,
    order: 'price_low_to_high',
    filter: null,
    is_day_use: false,
    is_including_occupied: false,
    room_count: 1,
    utm_campaign: null,
    utm_medium: null,
    utm_source: null,
    campaignid: null,
    adgroupid: null,
    keyword: null,
    gclid: null,
    yclid: null,
    medium: null,
    source: null,
    email_id: null,
    search_id: null
  },
  chatID: null,
  rooms: null,
  plans: null,
  preloadRooms: null,
  preloadPlans: null,
  isMultiple: false,
  galleryPhotos: [],
  galleryIndex: 0,
  cancellationPolicy: {
    content: null
  },
  selectedRooms: [],
  initAPIArrived: false,
  planDetails: {},
  roomDetails: {},
  totalPriceFromSearch: null,
  currentSearchParamsForAPI: null, // This holds the current search params that is sent to the API
  currentSearchParamsHotelPlans: [],
  currentSearchParamsRoomTypes: [],
  notificationMap: {},
  roomRateMap: {},
  promotionMap: {},
  wbfPriceMap: {},
  extraInventories: {},
  roomSearchSuccess: false,
  facilityAdditionalQuestionsSuccess: false
}
