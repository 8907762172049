<template>
  <div
    v-if="!$route.meta.closeButtonOnlyHeader"
    id="tripla-header-container-wrapper"
    v-modal-background="(showLangItems || showLoginItems) && isMobile"
  >
    <div
      v-if="isMobile"
      class="mobile-dropdown-container"
      :class="{ show: isMobile && (showLangItems || showLoginItems) }"
    >
      <portal-target v-show="showLangItems" name="language-list" />
      <portal-target v-show="showLoginItems" name="membership-setting" />
    </div>
    <div
      v-if="isInCBIframe ? isAppCloseable : true"
      class="sign-in-button-content"
      :class="{ 'float-top': isMobile && (showLangItems || showLoginItems) }"
    >
      <b-container>
        <b-link
          v-show="showBackButton"
          class="secondary-text back-button-container"
          :class="isMobile ? 'mobile' : ''"
          @click="goToSearchPage"
        >
          <arrow-left-grey-icon />
          <span class="mb-0 secondary-text">{{ $t('membershipForm.back') }}</span>
        </b-link>
        <div class="d-flex justify-content-end align-items-center">
          <multiple-currency-modal v-if="showCurrencySelector" />

          <template v-if="showLocaleSelector">
            <button
              id="language-popover"
              v-theme-font="isMobile && showLangItems"
              class="navbar-button mr-1"
              data-cy="lang-popover-toggle"
              @click="toggleMobileLanguage"
            >
              <i class="header-icon" :style="isMobile && showLangItems ? `fill: ${iconColor}` : ''"
                ><lang-icon class="lang-icon"
              /></i>
              {{ isMobile ? localeShortText[$i18n.locale] : localeText[$i18n.locale] }}
            </button>
            <b-popover
              v-if="!isMobile"
              ref="popover"
              custom-class="popover-no-padding"
              target="language-popover"
              triggers="click blur"
              placement="bottom"
              :show.sync="showLangItems"
              :delay="200"
            >
              <portal-target name="language-list" />
            </b-popover>
          </template>
          <login-widget
            v-if="showLoginWidget"
            :is-mobile="isMobile"
            :show-login-items="showLoginItems"
            @toggleLoginItems="toggleLoginItems"
          />
          <button v-if="isInIframe && isAppCloseable" class="close-btn" @click="closeWholeApp">
            <span class="close-text">{{ $t('base.close') }}</span>
            <close-icon />
          </button>
        </div>
      </b-container>
    </div>
    <div
      v-if="not503Page"
      id="tripla-header-container"
      ref="header"
      :class="{ 'only-mb': !isHeaderBackgroundColorWhite }"
    >
      <b-container v-if="!isCustomHeader" class="logo-content">
        <div class="hotel-info-container">
          <img v-if="logo" id="hotel-logo-image" class="img" :src="logo" />
          <h2 v-else class="hotel-name">{{ hotelName }}</h2>
        </div>
      </b-container>
      <div v-else v-dompurify-html="customHeaderHTML" />
    </div>
    <portal to="language-list">
      <b-button
        v-for="(locale, index) in locales"
        :key="index"
        :data-cy="`lang-popover-item-${locale}`"
        :data-cy-index="`lang-popover-item-index-${index}`"
        :class="$i18n.locale === locale ? 'selected' : ''"
        class="lang-option"
        variant="outline"
        @click="changeLocale(locale)"
        >{{ localeText[locale] }}
      </b-button>
    </portal>
  </div>
  <div
    v-else-if="$route.meta.closeButtonOnlyHeader && !isUnsubscribeEmailPath"
    class="close-btn-only-header"
  >
    <img class="wh-28px cursor-pointer" src="~assets/close.svg" @click="closeWholeApp" />
  </div>
</template>

<script>
import {
  appendStyleInHead,
  sendDataToParentWindow,
  isInIframe,
  isRealMobile
} from 'utilities/domOperation'
import { checkIsUnsubscribeEmailPath } from 'utilities/paramsFormat'
import LoginWidget from 'components/Navigation/LoginWidget'
import paths from 'routes/paths'
import { mapState, mapGetters } from 'vuex'
import _get from 'lodash/get'
import _each from 'lodash/each'
import ArrowLeftGreyIcon from 'assets/icons/arrow-left-grey.svg?inline'
import CloseIcon from 'assets/close.svg?inline'
import LangIcon from 'assets/language.svg?inline'
import goToSearchPageMixin from 'mixins/goToSearchPageMixin'
import { localeMapping, localeText, localeShortText } from 'locales/utilities'
import _values from 'lodash/values'
import MultipleCurrencyModal from 'components/MultipleCurrency/MultipleCurrencyModal.vue'
import triplaLocalStorage from 'utilities/triplaLocalStorage'

const allowRoutesForBackButton = [
  paths.myAccount.profile,
  paths.myAccount.reservations,
  paths.myAccount.points,
  paths.myAccount.paymentOptions,
  paths.organization.admin,
  paths.myAccount.promotions
]

export default {
  name: 'HeaderNavigation',
  components: {
    LoginWidget,
    ArrowLeftGreyIcon,
    CloseIcon,
    LangIcon,
    MultipleCurrencyModal
  },
  mixins: [goToSearchPageMixin],

  props: {
    logo: {
      type: [String, Boolean],
      required: true
    }
  },
  data() {
    return {
      showLangItems: false,
      showLoginItems: false,
      popover: false,
      localeText,
      localeShortText
    }
  },
  computed: {
    ...mapState({
      headerHTML: (state) => _get(state, 'setting.init.website_header_html', null),
      headerCSS: (state) => _get(state, 'setting.init.compiled_website_header_css', null)
    }),
    ...mapGetters({
      initialSetting: 'setting/getInit',
      isAppCloseable: 'setting/isAppCloseable',
      isInCBIframe: 'setting/isInChatbotIframe',
      isCustomHeader: 'setting/isCustomHeader',
      hotelName: 'setting/getHotelName',
      isUpdateMode: 'booking/isUpdateMode',
      iconColor: 'setting/getColor',
      isMcpEnabled: 'mcp/isMcpEnabled',
      getBookLanguages: 'setting/getBookLanguages'
    }),
    showLoginWidget() {
      return (
        !this.isInCBIframe &&
        this.showLocaleSelector &&
        !this.updateModeOnSearchPage &&
        this.not503Page &&
        this.$route.name !== paths.roomLottery.complete
      )
    },
    showBackButton() {
      return allowRoutesForBackButton.includes(this.$route.name)
    },
    updateModeOnSearchPage() {
      return this.isUpdateMode && this.$route.name === paths.booking.result
    },
    customHeaderHTML() {
      if (!this.headerHTML) return null
      let content = this.headerHTML
      if (this.headerHTML.includes('{{facility_name}}')) {
        content = content.replace(/{{facility_name}}/g, this.hotelName)
      }
      if (this.headerHTML.includes('{{brand_name}}')) {
        content = content.replace(/{{brand_name}}/g, this.initialSetting.hotel_brand_name)
      }
      return content
    },
    isInIframe,
    locales() {
      const bookLanguages = this.getBookLanguages

      if (bookLanguages.length > 0) {
        return bookLanguages
      }

      // P1 issue when solved add this.availableLanguages || in below
      return _values(localeMapping)
    },
    not503Page() {
      return ![paths.errorPages.serviceUnavailable].includes(this.$route.name)
    },
    showLocaleSelector() {
      return (
        ![
          paths.reservation.guestDetail,
          paths.reservation.payment,
          paths.roomLottery.guestDetail
        ].includes(this.$route.name) && !this.isInCBIframe
      )
    },
    showCurrencySelector() {
      const isCurrentPageDisplay = [paths.booking.result, paths.booking.roomsRecommender].includes(
        this.$route.name
      )

      return this.isMcpEnabled && isCurrentPageDisplay && !this.$route.query.current_reservation
    },
    isMobile() {
      return isRealMobile()
    },
    isUnsubscribeEmailPath() {
      return checkIsUnsubscribeEmailPath(window.location.pathname)
    },
    isHeaderBackgroundColorWhite() {
      return (
        getComputedStyle(document.documentElement).getPropertyValue('--theme-header-background') ===
        '#ffffff'
      )
    }
  },
  watch: {
    headerCSS: {
      handler(value) {
        if (value && this.isCustomHeader) appendStyleInHead(value)
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(this.treatHeaderLinks)

    const isAvailableLocale = this.locales.includes(this.$i18n.locale)

    if (!isAvailableLocale) {
      this.changeLocale(this.locales[0])
    }

    window.addEventListener('focus', this.checkLocaleAndUpdate)
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.checkLocaleAndUpdate)
  },
  methods: {
    checkLocaleAndUpdate() {
      const locale = triplaLocalStorage.getItem('triplabot_locale')
      if (!this.locales.includes(locale) || locale === this.$i18n.locale) {
        return
      }
      this.changeLocale(locale)
    },
    closeWholeApp() {
      sendDataToParentWindow({
        action: 'close'
      })
    },
    changeLocale(locale) {
      this.showLangItems = false
      this.$emit('changeLocale', locale)
    },
    toggleLoginItems(value) {
      this.showLoginItems = value
      if (value) {
        this.showLangItems = false
      }
    },
    treatHeaderLinks() {
      const header = this.$refs.header
      const aTags = header?.querySelectorAll('a') || []
      _each(aTags, (aTag) => {
        aTag.target = '_blank'
        aTag.setAttribute('rel', 'noopener noreferrer')
      })
    },
    toggleMobileLanguage() {
      if (this.isMobile) {
        this.showLangItems = !this.showLangItems
        this.showLoginItems = false
      }
    },
    // for modal-background
    closeBackground() {
      this.showLangItems = false
      this.showLoginItems = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/mixins.scss';
@import '~stylesheets/vars.scss';

.close-btn {
  border: none;
  cursor: pointer;
  outline: none;
  background: $background-light-gray;
  padding: 0;
  font-size: 14px;
  margin-left: 10px;

  .close-text {
    display: inline-block;
    line-height: 22px;
    font-weight: 700;
  }
}

.lang-option {
  text-align: left;
  width: 100%;
  display: block;
  border-radius: 0;

  &.selected {
    background: $sub-background;
  }
}

.login-close-wrapper {
  justify-content: flex-end;
}

#tripla-header-container-wrapper {
  background: white;

  .secondary-text {
    color: $secondary-elt;
  }

  .sign-in-button-content {
    background: $background-light-gray;
    padding: 7px 0;
    z-index: 102;
    position: relative;

    .back-button-container {
      display: inline-block;
      position: absolute;
      top: 9px;

      &.mobile {
        top: 12px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }

      .secondary-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
      }
    }

    &.float-top {
      z-index: 102;
    }
  }
}

#tripla-header-container {
  color: var(--theme-header-color);
  background: var(--theme-header-background);
  margin: 8px 0;
  &.only-mb {
    margin: 0 0 16px;
  }

  .logo-content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;

    .img {
      max-width: 100%;
      max-height: 50px;
    }

    .hotel-info-container {
      flex: 1 0 0%;
    }

    .hotel-name {
      margin-bottom: 0;
      font-size: clamp(1rem, 5vw, 2rem);
      font-stretch: expanded;
      word-break: break-word;
    }
  }

  @include media-breakpoint-up(lg) {
    .logo-content {
      flex-direction: row;
    }
  }
}

@include media-breakpoint-down(xs) {
  .close-btn .close-text {
    display: none;
  }

  .login-close-wrapper {
    justify-content: space-between;
  }
}

@include media-breakpoint-down(md) {
  #tripla-header-container .logo-content .img {
    max-height: 32px;
  }

  .mobile-dropdown-container {
    position: absolute;
    top: -100%;
    width: 100%;
    background: white;
    border-radius: 0 0 8px 8px;
    z-index: 102;
    transition: top 0.5s;

    &.show {
      top: 50px;
    }

    .lang-option {
      padding: 12px;
    }
  }
}

.close-btn-only-header {
  position: absolute;
  top: 11px;
  right: 9px;
  z-index: 1;
  background: $white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh-28px {
  width: 28px;
  height: 28px;
}
</style>
