import store from 'store'
import triplaLocalStorage from 'utilities/triplaLocalStorage'
export const userTokenKey = 'tripla_user_token'
const userTokenExtraDataKey = 'tripla_user_token_extra_data'

export function getUserToken() {
  const brandId = store && store.getters['setting/getBrandID']
  let result = ''
  if (triplaLocalStorage.isAvailable) {
    result =
      triplaLocalStorage.getItem(`${brandId}_${userTokenKey}`) ||
      store.getters['membership/getLoggedInToken']
  } else {
    result = store.getters['membership/getLoggedInToken']
  }
  return result
}

export function setUserToken(token) {
  const brandId = store && store.getters['setting/getBrandID']
  const key = `${brandId}_${userTokenKey}`
  if (triplaLocalStorage.isAvailable) {
    triplaLocalStorage.setItem(key, token)
    store.dispatch('membership/setLoggedInToken', token)
  } else {
    store.dispatch('membership/setLoggedInToken', token)
  }
}

export function removeUserToken() {
  const brandId = store && store.getters['setting/getBrandID']
  if (triplaLocalStorage.isAvailable) {
    triplaLocalStorage.removeItem(`${brandId}_${userTokenKey}`)
    triplaLocalStorage.removeItem(`${brandId}_${userTokenExtraDataKey}`)
    store.dispatch('membership/setLoggedInToken', null)
  } else {
    store.dispatch('membership/setLoggedInToken', null)
  }
}

export function getTokenExtraData() {
  const brandId = store && store.getters['setting/getBrandID']
  try {
    if (triplaLocalStorage.isAvailable) {
      return JSON.parse(triplaLocalStorage.getItem(`${brandId}_${userTokenExtraDataKey}`)) || {}
    }
  } catch {}
  return {}
}

export function setTokenExtraData(payload) {
  const brandId = store && store.getters['setting/getBrandID']
  const data = { ...getTokenExtraData(), ...payload }
  try {
    if (triplaLocalStorage.isAvailable) {
      return triplaLocalStorage.setItem(`${brandId}_${userTokenExtraDataKey}`, JSON.stringify(data))
    }
  } catch {}
  return false
}
