import triplaLocalStorage from 'utilities/triplaLocalStorage'

export function setupTriplaBotLocalStorage(query = {}) {
  if (query.hasOwnProperty('closed_icon_messages')) {
    let closedIconMessageIds = query.closed_icon_messages
    closedIconMessageIds =
      closedIconMessageIds.length > 0
        ? query.closed_icon_messages.split(',').map((id) => ({
            id: Number(id),
            last_closed: new Date()
          }))
        : []

    triplaLocalStorage.setItem('closed_icon_message_ids', JSON.stringify(closedIconMessageIds))
  }

  if (query.triplabot_minimized) {
    triplaLocalStorage.setItem('triplabot-icon-minimized', query.triplabot_minimized)
  }
}
