import sha256 from 'crypto-js/sha256'
import store from 'store'

export function encrypGA4Value(value) {
  // we do hashing twice here as requirement
  // https://umami-me.atlassian.net/wiki/spaces/TECH/pages/2188312628/Google+Analytcs+4+GA4
  const salt = sha256(store.getters['setting/getBrandID'].toString()).toString()
  return sha256(`${salt}${value}`).toString()
}

export function getGA4GlobalMeta() {
  const user =
    store.getters['booking/getTrackingInfoFromBw']?.currentUser ||
    store.getters['membership/getCurrentUser']
  return user.id
    ? {
        hotel_brand_id: store.getters['setting/getBrandID'],
        't-hotel_brand_id': store.getters['setting/getBrandID'],
        tripla_internal_id: encrypGA4Value(user.id),
        hashed_membership_id: encrypGA4Value(user.membership_id)
      }
    : {
        hotel_brand_id: store.getters['setting/getBrandID'],
        't-hotel_brand_id': store.getters['setting/getBrandID']
      }
}
