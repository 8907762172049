import EN from './en_locales'
import JA from './ja_locales'
import KO from './ko_locales'
import ZH_HANS from './zh_hans_locales'
import ZH_HANT from './zh_hant_locales'
import IN from './in_locales'
import TH from './th_locales'
import AR from './ar_locales'

export default {
  en: EN,
  ja: JA,
  ko: KO,
  zh_Hans: ZH_HANS,
  zh_Hant: ZH_HANT,
  id: IN,
  th: TH,
  ar: AR
}
